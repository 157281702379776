import Service from "./BaseService";

const get = () =>
  Service.get('/images/bank-info');

const save = (payload, config) =>
  Service.upload('/images/bank-info', payload, config);

const AccountService = {
  get,
  save
};

export default AccountService;