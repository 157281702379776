import Service from "./BaseService";

interface iParts {
  className: string;
  typeOfBreakdown: string;
}
interface iCalculatePricePayload {
  markName: string; // "TOYOTA";
  modelName: string; // "Harrier XU10";
  buildYear: string; // "2023";
  importYear: string; // "2022";
  cabinNumber: string; // "";
  plateNumber: string; // "7310УАЕ";
  phone: string; // "89764949";
  firstName: string; // "Буднаран";
  register: string; // "RZ93898987";
  parts: iParts[]; // [{ className: "Крыло хойд"; typeOfBreakdown: "хагарсан" }];
}
interface iCalculatePriceResponse {
  status: boolean;
  message: string;
  data: any;
}
const CalculatePrice = (payload: iCalculatePricePayload) =>
  Service.post("/parts-calc", payload).then((response) =>
    Promise.resolve<iCalculatePriceResponse>(response)
  );

interface iCheckDataPayload {
  buildYear: string;
  modelName: string;
  markName: string;
  vin: string;
}

const CheckData = (payload: iCheckDataPayload) =>
  Service.post("/check-data", payload).then((response) =>
    Promise.resolve<iCalculatePriceResponse>(response)
  );

const EstimationService = {
  CalculatePrice,
  CheckData,
};
export default EstimationService;
