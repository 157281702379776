import { CircularProgress as MuiCircularProgress } from "@mui/material"

const CircularProgress = () => {
  return (
    <MuiCircularProgress
      variant="determinate"
      value={50}
      thickness={2}
      size={48}
      sx={{
        color: '#09111B',
      }}
    />
  )
}

export default CircularProgress;