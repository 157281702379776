export const createCaseSlice = (set) => ({
  images: [],  // => base64 images 
  changed: false,
  active: 0, // => 1
  setImages: (images) => set(() => ({ images: images })),
  addImage: (image) => set((prev) => ({ images: [...prev.images, image] })),
  removeImage: (idx) => set((prev) => {
    let tmp = [...prev.images];
    tmp.splice(idx, 1)
    return { images: tmp }
  }),
  setActive: (active) => set(() => ({ active: active })),
  setChanged: (changed) => set(() => ({ changed: changed })),
})