import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../../store";
import Typography from "../../base-components/Typography";

function Home() {
  const [queryParams] = useSearchParams();
  const setToken = useStore.useSetToken();

  useEffect(() => {
    setTimeout(() => {
      window.location = "/victim";
    }, 5000);
  }, [queryParams, setToken]);

  return (
    <div className="bg-white absolute inset-0 flex">
      <div className="flex items-center justify-center w-full">
        <div className="relative flex space-x-1">
          <img src="/logos/z.svg" className="slide-left-in" alt="alt1" />
          <div className="flex space-x-1 show">
            <img
              src="/logos/u-1.svg"
              className="tracking-in-expand"
              alt="alt2"
            />
            <img src="/logos/r.svg" className="tracking-in-expand" alt="alt3" />
            <img src="/logos/a.svg" className="tracking-in-expand" alt="alt4" />
            <img src="/logos/g.svg" className="tracking-in-expand" alt="alt5" />
            <img
              src="/logos/u-1.svg"
              className="tracking-in-expand"
              alt="alt6"
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-20 left-6 right-6 text-center space-y-2.5">
        <Typography size="md">Цахим нөхөн төлбөрийн систем</Typography>
        <Typography size="sm">v 1.2</Typography>
      </div>
    </div>
  );
}

export default Home;
