import { twMerge } from "tailwind-merge";

interface TypographyProps {
  variant?: "Display" | "Headline" | "Title" | "Label" | "Body";
  size?: "lg" | "md" | "sm";
  underline?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Typography = (props: TypographyProps) => {
  const {
    variant = "Body",
    size = "lg",
    // type = "primary",
    underline = false,
    className,
  } = props;

  return (
    <>
      {variant === "Display" && (
        <h1
          {...props}
          className={twMerge([
            // type === "primary" && "text-primary",
            // type === "primary-soft" && "text-black-64",
            // type === "secondary" && "text-secondary",
            // type === "white" && "text-white",
            size === "lg" &&
              "text-[62px] leading-[64px] font-bold tracking-[1.25px]",
            size === "md" &&
              "text-[49px] leading-[52px] font-semibold tracking-[0.01em]",
            size === "sm" &&
              "text-4xl leading-[44px] font-semibold tracking-[0.0025em]",
            className,
          ])}
        >
          {props.children}
        </h1>
      )}
      {variant === "Headline" && (
        <h2
          {...props}
          className={twMerge([
            // type === "primary" ? "text-primary" : "text-on-surface-black-64",
            // type === "secondary" && "text-secondary",
            size === "lg" &&
              "text-[32px] leading-[40px] font-bold -tracking-[0.01em]",
            size === "md" &&
              "text-[28px] leading-[36px] font-bold -tracking-[0.005em]",
            size === "sm" && "text-2xl font-bold -tracking-[0.0175em]",
            className,
          ])}
        >
          {props.children}
        </h2>
      )}
      {variant === "Title" && (
        <h3
          {...props}
          className={twMerge([
            // type === "primary" ? "text-primary" : "text-on-surface-black-64",
            // type === "secondary" && "text-secondary",
            size === "lg" &&
              "text-[22px] leading-[28px] font-bold -tracking-[0.015em]",
            size === "md" && "text-base font-bold",
            size === "sm" && "text-sm font-bold -tracking-[0.25px]",
            className,
          ])}
        >
          {props.children}
        </h3>
      )}
      {variant === "Label" && (
        <h4
          {...props}
          className={twMerge([
            // type === "primary" ? "text-primary" : "text-black-64",
            // type === "secondary" && "text-secondary",
            size === "lg" && "text-sm font-semibold font-typeface",
            size === "lg" && underline && "underline tracking-[0.25px]",
            size === "md" && "text-xs font-medium font-typeface",
            className,
          ])}
        >
          {props.children}
        </h4>
      )}
      {variant === "Body" && (
        <h5
          {...props}
          className={twMerge([
            // type === "primary" ? "text-primary" : "text-black-64",
            // type === "secondary" && "text-secondary",
            // type === "white" && "text-white",
            size === "lg" && "font-typeface text-base font-normal",
            size === "md" && "font-typeface text-sm font-normal",
            size === "sm" && "font-typeface text-xs font-normal",
            className,
          ])}
        >
          {props.children}
        </h5>
      )}
    </>
  );
};

export default Typography;
