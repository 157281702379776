import { twMerge } from "tailwind-merge";

const Card = ({
  size = "md",
  className,
  children,
}: {
  size?: string;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={twMerge([
        "my-4 border border-dark-12 bg-white p-4",
        size === "lg" && "rounded-2xl p-6",
        size === "md" && "rounded-xl",
        size === "sm" && "rounded-lg p-3 my-2",
        size === "xs" && "rounded p-3",
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default Card;
