import { useRef, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Camera as CameraPro } from "react-camera-pro";
import { CameraType } from "react-camera-pro";

import DamageService from "../../services/DamageService";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera-1.svg";
import IconButton from "../../base-components/IconButton";
import Button from "../../base-components/Button";
import Typography from "../../base-components/Typography";

import useStore2 from "../../store/hook";

const CameraMask = () => {
  const params = useParams();
  const navigate = useNavigate();

  const image = useStore2.useImage();
  const setImage = useStore2.useSetImage();
  const setImageChanged = useStore2.useSetImageChanged();
  const cameraRef = useRef<CameraType>();
  const name = useStore2.useName();

  const page = useStore2.usePage();

  function capturePhoto() {
    if (cameraRef.current) {
      const imageSrc = cameraRef.current.takePhoto();
      setImage(imageSrc);
      setImageChanged(true);
    }
  }

  function handleContinue() {
    const payload: {
      [_: string]: string;
    } = {};
    if (typeof params?.location === "string" && params?.location !== "") {
      payload[params?.location] = image;
      DamageService.save(name, payload).then((response) => {
        if (response === "success") navigate(page);
      });
    }
  }

  return (
    <Fragment>
      {image ? (
        <div className="absolute inset-0 p-4 py-6 bg-gray space-y-4">
          <div className="flex justify-center border border-secondary-12 rounded-lg bg-white h-52">
            <img src={image} alt="damage" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button variant="primary-outlined" onClick={() => setImage("")}>
              Дахин дарах
            </Button>
            <Button onClick={() => handleContinue()}>Үргэлжлүүлэх</Button>
          </div>
        </div>
      ) : (
        <div className="absolute inset-0">
          <div className="h-full w-full inset-0">
            <CameraPro
              ref={cameraRef}
              facingMode="environment"
              errorMessages={{}}
            />
          </div>
          <div className="mt-12 absolute inset-x-0 bottom-8">
            <div className="grid p-9 gap-6">
              {/* <Typography size="md" type="white" className="text-center"> */}
              <Typography size="md" className="text-center">
                Зураг тод дарснаар нөхөн төлбөр шийдвэрлэхэд илүү хялбар болно
                шүү
              </Typography>
              <div className="flex justify-center gap-6">
                <IconButton
                  variant="secondary-outlined"
                  className="bg-white"
                  onClick={capturePhoto}
                >
                  <CameraIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CameraMask;
