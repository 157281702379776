import Service from "./BaseService";

const get = (name: string) =>
  Service.get("/images/damages?name=" + encodeURIComponent(name));

const save = (name: string, payload: any, config?: any) =>
  Service.upload(
    "/images/damages?name=" + encodeURIComponent(name),
    payload,
    config
  );

const submitDamage = (name: string, config?: any) =>
  Service.get("/images/damage-submit?name=" + encodeURIComponent(name), {
    ...config,
    nonLoading: true,
  });

const calcPrice = (config: any) =>
  Service.get("/images/calc-price", { ...config, nonLoading: true });

const summary = () => Service.get("/images/summary");

const savingDamageResult = (payload: any, config: any) =>
  Service.create(`/images/verify-result`, payload, config);

const DamageService = {
  get,
  save,
  submitDamage,
  calcPrice,
  savingDamageResult,
  summary,
};

export default DamageService;
