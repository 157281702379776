import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignaturePad from 'react-signature-canvas';

import SignatureService from "../../services/SignatureService";
import { isNullOrUndefined } from "../../utils/helper";
import Card from "../../components/Card";
import Typography from "../../base-components/Typography";
import IconButton from "../../base-components/IconButton";
import Button from "../../base-components/Button";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh-ccw.svg";

const Signature = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const canvasRef = useRef(null);

  function saveSignature() {
    SignatureService.save({ 'sign': image })
      .then((response) => {
        if (response === "success")
          navigate('/summary')
      })
  }

  function clearSignature() {
    canvasRef.current?.clear()
    setImage(null);
  }

  const endSignature = () => {
    setImage(canvasRef.current?.getTrimmedCanvas().toDataURL('image/png'));
  }

  return (
    <>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" />
        <Typography variant="Title" size="md">
          Гарын үсэг
        </Typography>
      </header>
      <Card className={"border-dashed h-96 relative"}>
        <IconButton
          onClick={clearSignature}
          variant="primary-outlined"
          className="absolute right-4"
        >
          <Refresh />
        </IconButton>
        <SignaturePad
          ref={canvasRef}
          canvasProps={{
            className: 'w-full h-full rounded-lg'
          }}
          penColor="#E2A95C"
          onEnd={endSignature}
        />
        <Typography variant="Label" className="text-center -mt-6">
          Та дэлгэц дээр гарын үсгээ зурна уу?
        </Typography>
      </Card>
      <Button
        disabled={isNullOrUndefined(image)}
        variant={
          isNullOrUndefined(image)
            ? "primary-outlined"
            : "primary-contained"
        }
        onClick={() => saveSignature()}
        className="w-full"
      >
        Илгээх
      </Button>
    </>
  )
}

export default Signature;