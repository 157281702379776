import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export interface IconButtonProps {
  className?: string;
  variant?:
    | "primary-contained"
    | "secondary-contained"
    | "primary-outlined"
    | "secondary-outlined"
    | "primary-soft"
    | "secondary-soft";
  size?: "sm" | "lg";
  children?: React.ReactNode;
  onClick?: () => void;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const className = props.className;
    const variant = props.variant || "primary-soft";
    const size = props.size || "lg";

    const generalStyles = [
      "flex items-center justify-center rounded-full bg-white p-2 cursor-pointer outline-none", // border border-dark-20
    ];

    // Sizes
    const small = ["w-10 h-10"];
    const large = ["w-12 h-12"];

    // Main color
    const primary = [
      "bg-primary", // Default
      "focus:bg-primary-focus focus:text-on-surface-white-64", // On Focus
      "[&:hover:not(:disabled)]:bg-primary-hover [&:hover:not(:disabled)]:text-on-surface-white-64", // On hover and not disabled
      "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
    ];
    const secondary = [
      "bg-secondary", // Default
      "focus:bg-secondary-focus", // On Focus
      "[&:hover:not(:disabled)]:bg-secondary-hover", // On hover and not disabled
      "disabled:bg-secondary-disabled disabled:text-on-surface-black-24", // Disabled
    ];

    // Outline
    const outlinePrimary = [
      "border border-dark-12 bg-white text-primary", // Default
      "focus:bg-primary-soft-while-pressing focus:text-black-64", // On Focus
      "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-black-64", // On hover and not disabled
      "disabled:bg-primary-disabled disabled:text-black-24", // Disabled
    ];
    const outlineSecondary = [
      "border border-secondary-20 bg-white text-on-surface-secondary", // Default
      "focus:bg-secondary-soft-focus focus:border-secondary-12", // On Focus
      "[&:hover:not(:disabled)]:bg-secondary-soft-while-pressing [&:hover:not(:disabled)]:border-secondary-12", // On hover and not disabled
      "disabled:bg-secondary-disabled disabled:text-on-surface-black-24 disabled:border-secondary-12", // Disabled
    ];

    // Soft color
    const primary_soft = [
      "bg-primary-soft text-primary", // Default
      "focus:bg-primary-soft-focus", // On Focus
      "[&:hover:not(:disabled)]:bg-primary-soft-hover", // On hover and not disabled
      "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
    ];

    const softSecondary = [
      "bg-secondary-soft text-on-surface-secondary", // Default
      "focus:bg-secondary-soft-focus", // On Focus
      "[&:hover:not(:disabled)]:bg-secondary-soft-hover", // On hover and not disabled
      "disabled:bg-secondary-disabled disabled:text-on-surface-black-24", // Disabled
    ];

    return (
      <button
        {...props}
        ref={ref}
        className={twMerge([
          generalStyles,
          size === "sm" && small,
          size === "lg" && large,
          variant === "primary-contained" && primary,
          variant === "secondary-contained" && secondary,
          variant === "primary-outlined" && outlinePrimary,
          variant === "secondary-outlined" && outlineSecondary,
          variant === "primary-soft" && primary_soft,
          variant === "secondary-soft" && softSecondary,
          className,
        ])}
      >
        {props.children}
      </button>
    );
  }
);

// IconButton.propTypes = {
//   className: PropTypes.string,
//   variant: PropTypes.string,
//   size: PropTypes.string,
// };

export default IconButton;
