import { Slider } from "@mui/material";

import { DAMAGE_LEVEL } from "../../utils/constant";

const SliderLevel = ({ level }) => {

  function thumbColor() {
    switch (level) {
      case DAMAGE_LEVEL.LOW:
        return '#06994D'
      case DAMAGE_LEVEL.MIDDLE:
        return '#EEC079'
      case DAMAGE_LEVEL.HIGH:
        return '#E7645A'
      default:
        return '#06994D'
    }
  }

  function sliderValue() {
    switch (level) {
      case DAMAGE_LEVEL.LOW:
        return 20
      case DAMAGE_LEVEL.MIDDLE:
        return 50
      case DAMAGE_LEVEL.HIGH:
        return 80
      default:
        return 20
    }
  }

  return (
    <Slider
      defaultValue={20}
      value={sliderValue()}
      disabled
      sx={{
        width: 244,
        '@media (max-width: 344px)': {
          width: '100%',
        },
        height: 6,
        padding: "0 !important",
        color: 'transparent !important',
        backgroundImage: 'linear-gradient(to right, #06994D 33.33%, #EEC079 33.33%, #EEC079 66.66%, #E7645A 66.66%)',
        '& .MuiSlider-thumb': {
          border: '4px solid white',
          background: thumbColor(),
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.24)',
        },
        '& .MuiSlider-rail': {
          opacity: 1,
        }
      }}
    />
  )
}

export default SliderLevel;
