import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useStore, useStoreBase } from "../../store";
import DamageService from "../../services/DamageService";
import Typography from "../../base-components/Typography";
import useStore2 from "../../store/hook";
import { DAMAGE_TYPE_ESTIMATION } from "../../utils/constant";
import EstimationService from "../../services/estimation/EstimationService";

const Calculations = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [wait, setWait] = useState(false);

  const setToken = useStore.useSetToken();
  const setDamagedParts = useStore2.useSetDamagedParts();
  const setTokenData = useStore.useSetTokenData();
  const vehicleInfo = useStore2.useVehicleInfo();
  const victim = useStore2.useVictim();
  const damagedParts = useStore2.useDamagedParts();
  const setEstimations = useStore2.useSetEstimations();

  useEffect(() => {
    // get first 4 digits of importDate
    const importDate = vehicleInfo?.importDate?.slice(0, 4);

    const payload = {
      markName: vehicleInfo?.make || "",
      modelName: vehicleInfo?.model || "",
      buildYear: vehicleInfo?.year.toString() || "",
      importYear: importDate || "",
      cabinNumber: vehicleInfo?.vin || "",
      plateNumber: vehicleInfo?.plateNumber || "",
      phone: victim?.mobile || "",
      firstName: victim?.name || "",
      register: victim?.registryNumber || "",
      parts: damagedParts.map((part) => {
        return {
          // className: part.part_name_mn,
          className: part.deloitte_part,
          typeOfBreakdown:
            DAMAGE_TYPE_ESTIMATION[part.damage_type].toLowerCase(),
        };
      }),
    };

    EstimationService.CalculatePrice(payload)
      .then((response) => {
        if (response.status === false) {
          useStoreBase.setState({
            notification: {
              message: response.message,
              type: "warning",
            },
          });
        } else {
          console.log(response.data);
          setEstimations(response.data);
          navigate("/invoice");
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    // DamageService.calcPrice({}).then((response) => {
    //   if (!!response.is_wait) {
    //     setWait(true);
    //   } else {
    //     setDamagedParts(response.data);
    //     setTokenData(response.token);
    //     navigate("/invoice");
    //   }
    // });
  }, [
    damagedParts,
    navigate,
    vehicleInfo?.importDate,
    vehicleInfo?.make,
    vehicleInfo?.model,
    vehicleInfo?.plateNumber,
    vehicleInfo?.vin,
    vehicleInfo?.year,
    victim?.mobile,
    victim?.name,
    victim?.registryNumber,
  ]);

  return (
    <div className="flex justify-center h-screen">
      <div className="h-full">
        <div className="relative h-2/3 flex justify-center items-center">
          <img src="/images/load-2.png" alt="vehicle-pic" className="blink" />
          <img
            src="/images/load-1.png"
            alt="load"
            color="#B3C0D0"
            className="absolute z-10"
          />
        </div>
        <div className="h-1/3">
          <Typography className="text-center p-8">
            {wait
              ? "Таны хохирлын үнэлгээг тооцоход 1-2 цаг болно."
              : "Хохирлын үнэлгээ тооцоолж байна. Та түр хүлээнэ үү..."}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Calculations;
