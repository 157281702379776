import React, { Fragment, forwardRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Popover } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { isNullOrUndefined } from "../../utils/helper";
import Calendar from "../Calendar";
import Typography from "../Typography";

const Date = forwardRef((props, ref) => {
  const {
    disabled = false,
    id = "",
    label,
    date = dayjs(),
    className,
    placement = "left",
    onChangeDate = () => { },
    minDate,
    maxDate
  } = props;
  const [selectedDate, setSelectedDate] = useState(date);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    setAnchorEl(e.currentTarget);
    // setIsShow(!isShow);
    // props.handleClick(e);
  }
  const handleChangeDate = (date) => {
    onChangeDate(date);
    setSelectedDate(date);
    // setIsShow(false);
  }

  const open = Boolean(anchorEl);

  return (
    <div className='relative cursor-pointer w-full' >
      <label
        className={twMerge([
          'absolute top-0 z-10 ml-1 translate-x-12 translate-y-4 text-sm font-semibold text-black-64 transition-all duration-300 pointer-events-none',
          !isNullOrUndefined(date) && 'translate-y-1 text-xs',
        ])}
        htmlFor={id}
      >
        {label}
      </label>
      <button
        id={id}
        onClick={handleClick}
        // onBlur={(e) => { e.stopPropagation(); setIsShow(false); }}
        className={twMerge([
          'transition duration-200 ease-in-out w-full text-sm text-primary border-dark-12 border p-4 shadow-sm rounded-lg font-medium tracking-wide outline-none bg-white',
          open && 'border-black-24',
          className,
        ])}>
        <div className='flex'>
          <CalendarIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <Typography variant="Label" className={twMerge(["block truncate mx-4", disabled && "text-black-64"])}>
            {date.format("YYYY/MM/DD") || selectedDate.format("YYYY/MM/DD")}
          </Typography>
        </div>
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '1rem',
          }
        }}
      >
        <div {...props}>
          <Calendar
            value={selectedDate}
            onChange={handleChangeDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </Popover>
    </div>
  )
})

Date.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  placement: PropTypes.string,
  onChangeDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date)
}

export default Date;