import Service from "./BaseService";

const get = () =>
  Service.get('/images/sign');

const save = (payload, config) =>
  Service.upload('/images/sign', payload, config);

const SignatureService = {
  get,
  save,
};

export default SignatureService;