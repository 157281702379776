import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react"
import { ReactComponent as Success } from "../../assets/icons/check-circle-2.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Warning } from "../../assets/icons/alert-circle.svg";
import { ReactComponent as Error } from "../../assets/icons/cross-circle.svg";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import Typography from "../Typography";

const AlertModal = (props) => {
  const {
    isOpen,
    onClose = () => { },
    type,
    size,
  } = props;


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary/[0.64]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center mx-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={twMerge([
                "w-full sm:w-[380px] md:w-[380px] lg:w-[380px] xl:w-[380px] transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all",
                size === "lg" && "max-w-lg"
              ])}>
                <div className="text-center">
                  <div className="flex justify-center">
                    <div className={
                      twMerge(['rounded-full p-4',
                        type === "success" && 'bg-success-soft',
                        type === "warning" && 'bg-warning-soft',
                        type === "info" && 'bg-info-soft',
                        type === "error" && 'bg-error-soft',
                      ])}>
                      {type === "success" && <Success stroke="#0E8C4A" className="w-6 h-6 mx-auto" />}
                      {type === "warning" && <Warning stroke="#E3A13A" className="w-6 h-6 mx-auto" />}
                      {type === "info" && <Info stroke="#1865D8" className="w-6 h-6 mx-auto" />}
                      {type === "error" && <Error stroke="#B7281E" className="w-6 h-6 mx-auto" />}
                    </div>
                  </div>
                  {props.children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

AlertModal.Title = ({ className, ...props }) => {
  return (
    <Typography
      variant="Label"
      className={twMerge([
        "mt-5 text-primary",
        className
      ])}>
      {props.children}
    </Typography>
  )
}

AlertModal.Message = ({ className, ...props }) => {
  return (
    <div className={twMerge([
      "mt-2 text-black-64",
      className
    ])}>
      {props.children}
    </div>
  )
}

AlertModal.Footer = ({ className, ...props }) => {
  return (
    <div className={twMerge([
      "pt-4 px-6 flex justify-center align-center border-t border-dark-12 -mx-8",
      className,
    ])}>
      {props.children}
    </div>
  )
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  footer: PropTypes.element,
}

export default AlertModal;