import { useNavigate } from "react-router";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as Camera } from "../../../assets/icons/camera.svg";
import IconButton from "../../../base-components/IconButton";
import Typography from "../../../base-components/Typography";
import Card from "../../../components/Card";

const DamageInstructions = () => {
  const navigate = useNavigate();

  return (
    <div className="absolute inset-0 bg-gray p-4 pt-6 h-max">
      <Arrow className="h-[18px] w-[18px] cursor-pointer" onClick={() => navigate('/damages')} />
      <Typography variant="Title" className="mt-6">
        Хэрхэн зураг авах вэ?
      </Typography>
      <div className="my-6 space-y-4">
        <header className="inline-flex items-center space-x-2">
          <div>
            <div className="border border-dark-12 rounded-full bg-white inline-flex h-8 w-8 flex justify-center items-center">
              <Typography variant="Title" size="md">1</Typography>
            </div>
          </div>
          <Typography variant="Title" size="sm">
            Даатгалын тохиолдлын орчин нөхцөлийн зургийг бүтнээр нь оруулна
          </Typography>
        </header>
        <Card size="lg" className='py-10'>
          <div className="relative h-52 flex justify-center items-end py-2 p-10">
            <div className="absolute flex justify-center w-full -top-4">
              <img src="/sample-pics/default.png" alt="default opacity sample" className="h-[120px] opacity-20 rotate-[245deg] inline-flex -mb-5" />
            </div>
            <img src="/sample-pics/default.png" alt="default-sample" className="h-[120px]" />
            <div className="absolute inset-x-0 -top-6 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-4 left-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-4 right-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-[calc(100%/2-10px)] left-12 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-[calc(100%/2-10px)] right-12 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute bottom-0 left-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute bottom-0 right-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute inset-x-0 -bottom-10 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
          </div>
          <Typography className='mt-14'>
            Ойрхон байрлах замын тэмдэг, уулзвар, хязгаарлалтууд, автомашинууд улсын дугаарын хамт бүрэн орсон байх ёстойг анхаарна уу.
          </Typography>
        </Card>
      </div>
      <div className="my-6 space-y-4">
        <header className="inline-flex items-center space-x-2">
          <div>
            <div className="border border-dark-12 rounded-full bg-white inline-flex h-8 w-8 flex justify-center items-center">
              <Typography variant="Title" size="md">2</Typography>
            </div>
          </div>
          <Typography variant="Title" size="sm">
            Тээврийн хэрэгсэлийн 8 талаас зураг авна
          </Typography>
        </header>
        <Card size="lg" className='p-10'>
          <div className="relative h-52 flex justify-center items-center py-2 mb-6">
            <img src="/sample-pics/default.png" alt="default-sample" className="h-[120px]" />
            <div className="absolute inset-x-0 -top-6 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-4 left-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-4 right-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-[calc(100%/2-10px)] left-12 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute top-[calc(100%/2-10px)] right-12 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute bottom-0 left-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute bottom-0 right-12 grid place-content-baseline">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
            <div className="absolute inset-x-0 -bottom-10 grid place-content-center">
              <IconButton variant="primary-outlined" size="sm">
                <Camera />
              </IconButton>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default DamageInstructions;