import { StateCreator } from "zustand";

export interface iDamages {
  [front: string]: string;
  left: string;
  right: string;
  back: string;
}

export interface iDamage {
  damages: iDamages;
  setDamages: (damages: iDamages) => void;
}

const createDamagedSlice: StateCreator<iDamage> = (set) => ({
  damages: { left: "", right: "", front: "", back: "" },
  setDamages: (damages) => set(() => ({ damages: damages })),
});
export default createDamagedSlice;
