import axios from "axios";

const json_headers = {
  "Content-type": "application/json",
  Accept: "application/json",
};

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  json_headers,
});
