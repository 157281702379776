import { twMerge } from "tailwind-merge";
import Typography from "../../base-components/Typography";

interface TypeProps {
  field: string;
  value: string;
  className?: string;
}

const Tile = ({ field, value, className }: TypeProps) => {
  return (
    <div className={twMerge(["py-2.5 border-b border-dark-12", className])}>
      <Typography size="sm" className="text-black-64">
        {field}
      </Typography>
      <Typography variant="Title" size="sm">
        {value}
      </Typography>
    </div>
  );
};

export default Tile;
