import { useNavigate } from "react-router";
import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import Select from "../../../base-components/Select";

const list = [
  {
    id: 1,
    name: 'Хажуу талынхаа машиныг мөргөсөн'
  }
]

const Reason = () => {
  const navigate = useNavigate();

  return (
    <div className="rounded-xl bg-white border border-dark-12 p-4 space-y-8">
      <Typography variant="Title" size="md" className="text-black-64">
        Та эвдрэлийг хүлээн зөвшөөрөхгүй байгаа шалтгаанаа оруулна уу.
      </Typography>
      <Select
        label={'Шалтгаан'}
        options={list}
        value={list[0]}
      />
      <div className="grid grid-cols-2 gap-4">
        <Button variant="primary-soft" onClick={() => navigate('/invoice')}>Буцах</Button>
        <Button onClick={() => navigate('warning')}>Үргэлжлүүлэх</Button>
      </div>
    </div>
  )
}

export default Reason;