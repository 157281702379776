import http from "./HttpClient";
import { useStoreBase } from "../store";

http.interceptors.request.use((config) => {
  const paramConfig = config;
  const token = useStoreBase.getState().token;
  paramConfig.headers.Authorization = `Bearer ${token}`;
  return paramConfig;
});

function interceptError(error) {
  if (error.response?.status === 401) {
    useStoreBase.setState({
      notification: { message: error.response?.data?.detail, type: "warning" },
    });
    return;
  }

  if (error.response?.status >= 401 && error.response?.data?.detail) {
    useStoreBase.setState({
      notification: {
        message: JSON.stringify(error.response?.data?.detail),
        type: "warning",
      },
    });
    return;
  }

  console.log(process.env.NODE_ENV, "NODE_ENV");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    useStoreBase.setState({
      notification: {
        message: error.code + " => " + error.message + " => " + error.stack,
        type: "error",
      },
    });
  else
    useStoreBase.setState({
      notification: {
        message: error.code + " => " + error.message,
        type: "error",
      },
    });
}

const get = (url, config) => {
  if (!config?.nonLoading) useStoreBase.setState({ loading: true });
  return http
    .get(url, config)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      interceptError(error);
      return Promise.reject(error);
    })
    .finally(() => {
      if (!config?.nonLoading) useStoreBase.setState({ loading: false });
    });
};

const show = (url, config = {}) => {
  if (!config?.nonLoading) useStoreBase.setState({ loading: true });
  return http
    .get(url, config)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      interceptError(error);
      return Promise.reject(error);
    })
    .finally(() => {
      if (!config?.nonLoading) useStoreBase.setState({ loading: false });
    });
};

const create = (url, payload, config = {}) => {
  if (!config?.nonLoading) useStoreBase.setState({ loading: true });
  return http
    .post(url, payload, config)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      interceptError(error);
      return Promise.reject(error);
    });
};

const update = (url, payload, config = {}) => {
  if (!config?.nonLoading) useStoreBase.setState({ loading: true });
  return http
    .put(url, payload)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      interceptError(error);
      return Promise.reject(error);
    })
    .finally(() => {
      if (!config?.nonLoading) useStoreBase.setState({ loading: false });
    });
};

const remove = (url, config = {}) => {
  if (!config?.nonLoading) useStoreBase.setState({ loading: true });
  return http
    .delete(url)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      interceptError(error);
      return Promise.reject(error);
    })
    .finally(() => {
      if (!config?.nonLoading) useStoreBase.setState({ loading: false });
    });
};

const upload = (url, payload, config = {}) => {
  if (!config?.nonLoading) useStoreBase.setState({ loading: true });
  return http
    .post(url, payload, config)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      interceptError(error);
      return Promise.reject(error);
    })
    .finally(() => {
      if (!config?.nonLoading) useStoreBase.setState({ loading: false });
    });
};

const BaseService = {
  get,
  show,
  create,
  update,
  remove,
  upload,
};

export default BaseService;
