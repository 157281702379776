import { StateCreator } from "zustand";
export type Victim = {
  lastName: string;
  name: string;
  registryNumber: string;
  mobile: string;
  address: string;
};
export type iVictim = {
  victim: Victim;
  setVictim: (victim: Victim) => void;
};

const createVictimSlice: StateCreator<iVictim> = (set) => ({
  victim: {
    lastName: "",
    name: "",
    registryNumber: "",
    mobile: "",
    address: "",
  },
  setVictim: (victim) =>
    set(() => ({
      victim: {
        lastName: victim.lastName,
        name: victim.name,
        registryNumber: victim.registryNumber,
        mobile: victim.mobile,
        address: victim.address,
      },
    })),
});

export default createVictimSlice;
