import Service from "./BaseService";

const get = () =>
  Service.get(`/images/vin`);

const save = (payload, config) =>
  Service.upload(`/images/vin`, payload, config);

const VinService = {
  get,
  save,
};

export default VinService;