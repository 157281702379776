import { StateCreator } from "zustand";
import { formatDateToYYMMDDHHMMSS } from "../utils/helper";

export interface iVehicle {
  vin: string;
  plateNumber: string;
  make: string;
  model: string;
  color: string;
  year: string;
  importDate: string;
}

export interface iObject {
  object: any;
  vehicleInfo: iVehicle | null;
  isXyp: boolean;
  createDate: string;
  setObject: (object: any) => void;
  setVehicleInfo: (vehicle: iVehicle) => void;
  setIsXyp: (isXyp: boolean) => void;
  setCreateDate: () => void;
}

const createObjectSlice: StateCreator<iObject> = (set) => ({
  object: null,
  vehicleInfo: null,
  isXyp: false,
  createDate: "",
  setObject: (object) => set(() => ({ object: object })),
  setVehicleInfo: (vehicle: iVehicle) => set(() => ({ vehicleInfo: vehicle })),
  setIsXyp: (isXyp) => set(() => ({ isXyp: isXyp })),
  setCreateDate: () =>
    set(() => ({
      createDate: formatDateToYYMMDDHHMMSS("Asia/Ulaanbaatar"),
    })),
});

export default createObjectSlice;
