import { useNavigate } from "react-router";

import { useStore } from '../../store';
import { ReactComponent as Warning } from "../../assets/icons/alert-circle.svg";
import Button from "../../base-components/Button";
import Typography from "../../base-components/Typography";

const XypConfirm = () => {
  const navigate = useNavigate();

  const setIsXyp = useStore.useSetIsXyp();

  return (
    <div className="absolute inset-0 bg-gray p-4 grid place-content-center">
      <div className="overflow-hidden rounded-xl bg-white border border-dark-12 p-4 space-y-8">
        <div className="p-5 text-center">
          <div className="flex justify-center mb-8">
            <div className='rounded-full p-4 bg-warning-soft'>
              <Warning stroke="#E3A13A" className="w-6 h-6 mx-auto" />
            </div>
          </div>
          <Typography variant="Title" size="md">
            Хохирогч жолоочийн мэдээллийг
            ХУР-аас татахыг зөвшөөрч байна уу?
          </Typography>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button variant="primary-soft" onClick={() => { setIsXyp(false); navigate('/user-info'); }}>Үгүй</Button>
          <Button onClick={() => { setIsXyp(true); navigate('/user-info'); }}>Тийм</Button>
        </div>
      </div>
    </div>
  )
}

export default XypConfirm;