import { forwardRef, useImperativeHandle, useState } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { useStore } from "../../store";
import { ReactComponent as Close } from "../../assets/icons/cross.svg";
import { ReactComponent as Success } from "../../assets/icons/check-circle-2.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Warning } from "../../assets/icons/alert-circle.svg";
import { ReactComponent as Error } from "../../assets/icons/cross-circle.svg";
import Typography from "../Typography";

const Notification = forwardRef((
  {
    type = Types.error,
    message = "",
    title = "Алдаа гарлаа"
  },
  ref
) => {
  const [showNotification, setShowNotification] = useState(false);
  const setNotification = useStore.useSetNotification();

  const closeNotification = () => {
    setNotification(null)
    setShowNotification(false);
  }

  useImperativeHandle(ref, () => ({
    show() {
      setShowNotification(true);
      setTimeout(() => {
        closeNotification()
      }, 8000);
    }
  }))

  return (
    <div className="fixed right-0 top-6 bottom-auto box-border m-0 p-0 mr-6 z-50">
      <div className={twMerge([
        "relative w-[384px] max-h-[800px] max-w-[calc(100vw-48px)] ms-auto px-5 py-6 overflow-hidden break-words bg-white rounded-lg shadow-navigation",
        showNotification ? "block" : "hidden",
      ])}>
        <div className="box-border relative flex space-x-4">
          <div>
            {type === Types.info && <Info />}
            {type === Types.success && <Success stroke="#0E8C4A" />}
            {type === Types.warning && <Warning stroke="#E3A13A" />}
            {type === Types.error && <Error stroke="#B7281E" />}
          </div>
          <div className="space-y-2 w-[307px] text-primary">
            <Typography variant="Title" size="md">{title}</Typography>
            <Typography size="md" className="max-[136px] overflow-auto">{message}</Typography>
          </div>
          <button className="rounded-full absolute -top-1 -right-1 hover:bg-primary-soft p-1">
            <Close fill="#0A213A" className="h-5 w-5" onClick={() => closeNotification()} />
          </button>
        </div>
      </div>
    </div>
  )
})

export const Types = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
}

Notification.propTypes = {
  notificationType: PropTypes.oneOf(Object.keys(Types)),
  children: PropTypes.element,
}

export default Notification;