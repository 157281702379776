import Service from "./BaseService";

const makeList = (config) => 
  Service.get('/info/makes', { ...config, nonLoading: true});

const modelList = (config) => 
  Service.get('/info/models', { ...config, nonLoading: true});

const colorList = (config) => 
  Service.get('/info/colors', { ...config, nonLoading: true});

const yearList = (config) => 
  Service.get('/info/years', { ...config, nonLoading: true});

const InfosService = {
  makeList,
  modelList,
  colorList,
  yearList,
};

export default InfosService;