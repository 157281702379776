import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../base-components/Button";
import Input from "../../base-components/Input";
import Typography from "../../base-components/Typography";
import Card from "../../components/Card";
import Tile from "../../components/Tile";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";
import { ReactComponent as User } from "../../assets/icons/user-1.svg";
import { useNavigate } from "react-router";

const XypVictimInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      registry_number: "",
    },
  });
  const [isShowInfo, setIsShowInfo] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsShowInfo(true);
  };

  return (
    <>
      <Typography variant="Title" size="md" className="w-72">
        Хохирогч жолоочийн мэдээллийг оруулна уу?
      </Typography>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className={"space-y-4"}>
          <div>
            <Input
              label="Регистерийн дугаар оруулна уу?"
              className="w-full"
              {...register("registry_number", {
                required: true,
              })}
            />
            {errors?.plate_number?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Улсын дугаараа оруулна уу.
              </p>
            )}
          </div>
          <Button className="w-full">ХУР-аас татах</Button>
        </form>
      </Card>
      {isShowInfo && (
        <>
          <Card>
            <header className="flex items-center space-x-3">
              <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
                <User fill="#E3A13A" className="w-4 h-4" />
              </div>
              <Typography variant="Title" size="md">
                Даатгуулагчийн мэдээлэл
              </Typography>
            </header>
            <div className="grid gap-y-2">
              <Tile field={"Овог"} value={"*************"} />
              <Tile field={"Нэр"} value={"*************"} />
              <Tile field={"Регистерийн дугаар"} value={"*******17"} />
              <Tile field={"Утас"} value={"*********"} />
              <Tile field={"Жолооны үнэмлэхний дугаар"} value={"******"} />
              <Tile
                field={"Гэрийн хаяг"}
                value={
                  "**********************************************************"
                }
                className="border-none"
              />
            </div>
          </Card>
          <div className="absolute bottom-4 inset-x-0 px-4">
            <Button
              icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
              onClick={() => navigate("/xyp-vehicle-info")}
              className="w-full mt-8"
            >
              Баталгаажуулах
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default XypVictimInfo;
