export const DAMAGE_TYPE = {
  scratch: "Зурагдсан",
  dent: "Хонхойсон",
  crack: "Хагарсан",
  severely: "Их гэмтэлтэй",
};
export const DAMAGE_TYPE_ESTIMATION = {
  scratch: "зурагдсан",
  dent: "хонхойсон",
  crack: "хагарсан",
  severely: "хагарсан",
};

export const DAMAGE_LEVEL_MN = {
  low: "Бага",
  middle: "Дунд",
  high: "Их",
};

export const DAMAGE_LEVEL = {
  LOW: "low",
  MIDDLE: "middle",
  HIGH: "high",
};

export const REPAIR_TYPE = {
  change: "Солих",
  repair: "Засах",
};

export const DIMENSIONS = {
  WIDTH: 1920,
  HEIGHT: 1080,
};
