import { useEffect } from "react";
import useStore2 from "../../store/hook";
import Typography from "../../base-components/Typography";
import DamageService from "../../services/DamageService";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import Button from "../../base-components/Button";
import SidesButton from "../../components/SidesButton";
import { useNavigate, useLocation } from "react-router-dom";
import { iDamages } from "../../store/damageSlice";

const Damages = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const damages = useStore2.useDamages();
  const setDamages = useStore2.useSetDamages();
  const setImage = useStore2.useSetImage();
  const setPage = useStore2.useSetPage();
  const name = useStore2.useName();

  useEffect(() => {
    console.log(name);
    DamageService.get(name).then((response) => setDamages(response.data));
  }, [name, setDamages]);

  function handleClick(loc: string) {
    setImage(!!damages[loc] ? damages[loc] : "");
    setPage(location.pathname);
    navigate(`/camera-mask/${loc}`);
  }

  const check = (damages: iDamages) => {
    for (var key in damages) if (!!damages[key]) return true;
    return false;
  };

  return (
    <div>
      <Typography variant="Title" size="md" className="w-56">
        Машины үнэлгээ хийх талыг сонгоно уу?
      </Typography>
      <Typography size="md" className="text-secondary-150 flex space-x-3 mt-4">
        <Question stroke="#996D33" className="w-[18px] h-[18px]" />
        <span>Машиныг бүтэн багтааж зураг авна уу.</span>
      </Typography>
      <div className="flex justify-center my-20">
        <div className="relative p-10">
          <div className="flex justify-center">
            <img src="/sample-pics/simple.png" alt="" className="" />
          </div>
          <SidesButton
            onClick={() => handleClick("front")}
            isIncluded={!!damages["front"]}
            className="-top-8 inset-x-0 flex justify-center"
          />
          <SidesButton
            onClick={() => handleClick("left")}
            isIncluded={!!damages["left"]}
            className="inset-y-0 flex flex-col justify-center -left-6"
          />
          <SidesButton
            onClick={() => handleClick("right")}
            isIncluded={!!damages["right"]}
            className="inset-y-0 flex flex-col justify-center -right-6"
          />
          <SidesButton
            onClick={() => handleClick("back")}
            isIncluded={!!damages["back"]}
            className="-bottom-8 inset-x-0 flex justify-center"
          />
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          variant="primary-outlined"
          icon={<Question className="w-[18px] h-[18px]" />}
          onClick={() => navigate("/take-photos-instructions")}
        >
          Хэрхэн зураг авах вэ?
        </Button>
      </div>
      {check(damages) && (
        <div className="absolute bottom-4 inset-x-0 px-4">
          <Button className="w-full" onClick={() => navigate("/progress")}>
            Үргэлжлүүлэх
          </Button>
        </div>
      )}
    </div>
  );
};

export default Damages;
