import { useEffect } from "react";
import { useStore } from "../../store";
import CaseService from "../../services/CaseService";
import Typography from "../../base-components/Typography";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import { ReactComponent as PlusCircle } from "../../assets/icons/plus-circle-1.svg";
import { ReactComponent as Cross } from "../../assets/icons/cross-circle.svg";
import Button from "../../base-components/Button";
import TakePhoto from "../../components/TakePhoto";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Sides = () => {
  const params = useParams();
  const navigate = useNavigate();

  const setPage = useStore.useSetPage();
  const image = useStore.useImage();
  const setImage = useStore.useSetImage();
  const images = useStore.useImages();
  const setImages = useStore.useSetImages();
  const addImage = useStore.useAddImage();
  const removeImage = useStore.useRemoveImage();
  const active = useStore.useActive();
  const setActive = useStore.useSetActive();

  function convertCase(data) {
    if (!data || Object.keys(data).length === 0) return;
    const tmp_images = Object.values(data)
    setImages(tmp_images);
  }

  useEffect(() => {
    if (!params.mode) {  
      setImage('')
      CaseService.get().then((response) => convertCase(response.data))
    }
	}, [])

  useEffect(() => {
		if(!image) return;

    addImage(image)
    setImage('')
	}, [image])

  function saveCase() {
    const payload = { 'images': images }
    CaseService.save(payload)
      .then(() => {
        navigate('/vin')
      })
  }

  const handleTakePhoto = () => {
    setImage('')
    setPage('/case')
    navigate('/camera');
  }

  const renderImages = cases =>
    cases.map((img, idx) => (
      <div 
        key={`case-${idx}`}
        onClick={() => setActive(idx)}
        className={
          twMerge([
            "relative border border-white/[24%] rounded-lg h-20 w-36 bg-white inline-flex justify-center",
            idx === active && "border-2 border-primary"
        ])}
      >
        <div className="absolute top-2.5 right-2.5 inline-flex" onClick={() => removeImage(idx)}>
          <Cross stroke="#09111B" className="w-[18px] h-[18px]"/>
        </div>
        <img src={img} alt={`case-${idx}`} />
      </div>
    ));

  return (
    <div>
      <Typography variant="Title" size="md">
        Даатгалын тохиолдлын орчин нөхцөлийн
        зургийг оруулна уу?
      </Typography>
      <div className="mt-4 py-2">
        {
          images.length > 0 ?
            <div className="space-y-4">
              <div className="flex justify-center border border-secondary-12 rounded-lg bg-white h-52">
                <img src={ active > -1 ? images[active] : '/images/pic.png'} alt="active" />
              </div>
              <div className="overflow-x-auto scrollbar-hide">
                <div className="inline-flex space-x-3">
                  <button
                    onClick={() => handleTakePhoto()}
                    className="outline-none border border-dashed border-dark-20 rounded-lg p-2 h-20 w-36 bg-white"
                  >
                    <div className="flex justify-center">
                      <PlusCircle fill="#E2A95C" className="flex justify-center" />
                    </div>
                    <Typography variant="Label">
                      Зураг нэмэх
                    </Typography>
                  </button>
                  { renderImages(images) }
                </div>
              </div>
            </div>
            :
            <TakePhoto onTakePhoto={() => handleTakePhoto()}/>
        }
        <Button
          variant="primary-outlined"
          icon={<Question />}
          className="w-full mt-4"
        >
          Хэрхэн зураг авах вэ?
        </Button>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <Question stroke="#465972" className="w-[18px] h-[18px]" />
        <Typography size="md" type="primary-soft">
          2-3 метрийн зайнаас зургаа авна уу
        </Typography>
      </div>
      {
        images.length > 0 &&
        <div className="absolute bottom-4 inset-x-0 px-4">
          <Button className="w-full" onClick={() => saveCase()}>
            Үргэлжлүүлэх
          </Button>
        </div>
      }
    </div>
  )
}

export default Sides;