import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";
import useStore2 from "../../store/hook";
import DamageService from "../../services/DamageService";
import { parseValue, stringValue } from "../../utils/helper";
import InvoiceComponent from "../../components/Invoice";
import Card from "../../components/Card";
import Typography from "../../base-components/Typography";
import Button from "../../base-components/Button";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";

const Summary = () => {
  const navigate = useNavigate();

  const [sign, setSign] = useState("");
  const setDamagedParts = useStore2.useSetDamagedParts();
  const tokenData = useStore.useTokenData();
  const setTokenData = useStore.useSetTokenData();

  useEffect(() => {
    DamageService.summary().then((response) => {
      setDamagedParts(response.data);
      setSign(response.sign?.sign);
      setTokenData(response.token);
    });
  }, []);

  return (
    <>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" />
        <Typography variant="Title" size="md">
          Хураангуй
        </Typography>
      </header>
      <Card className="space-y-4">
        <section className="rounded-lg bg-primary relative flex justify-between py-4 px-3 mb-2">
          <div className="absolute right-0 inset-y-0 inline-flex">
            <img src="/images/icon.png" alt="icon" />
          </div>
          <div>
            <Typography size="sm" className="text-white/[64%]">
              Улсын дугаар:
            </Typography>
            <Typography variant="Title" size="sm" className="text-white">
              {stringValue(tokenData?.plate_number)}
            </Typography>
            <Typography size="sm" className="text-white/[64%] mt-2">
              Машины үйлдвэр, Марк:
            </Typography>
            <Typography variant="Title" size="sm" className="text-white">
              {stringValue(parseValue(tokenData?.make_id)) +
                " " +
                stringValue(parseValue(tokenData?.model_id))}
            </Typography>
          </div>
          <div>
            <Typography size="sm" className="text-white/[64%]">
              Гарын үсэг:
            </Typography>
            {sign && (
              <div className="max-w-[90px] max-h-[68px] inline-flex mt-2">
                <img src={sign} alt="sign" className="z-10" />
              </div>
            )}
          </div>
        </section>
        <InvoiceComponent />
      </Card>
      <Button
        onClick={() => navigate("/successful")}
        icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
        className="w-full mt-6"
      >
        Дуусгах
      </Button>
    </>
  );
};

export default Summary;
