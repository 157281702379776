import { useNavigate } from "react-router";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";
import Card from "../../components/Card";
import InvoiceComponent from "../../components/Invoice";
import Typography from "../../base-components/Typography";
// import AlertComponent from "../../base-components/Alert";
import Button from "../../base-components/Button";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";

const Invoice = () => {
  const navigate = useNavigate();

  return (
    <div>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" />
        <Typography variant="Title" size="md">
          Нэхэмжлэх дүн
        </Typography>
      </header>
      <Card>
        <InvoiceComponent />
      </Card>
      {/* <AlertComponent variant="warning">
        Та хохирлын үнэлгээг хүлээн зөвшөөрч байна уу.
      </AlertComponent> */}
      <div className="mt-4">
        {/* <Button
          variant="primary-outlined"
          textSize="md"
          onClick={() => navigate("/price-not-allowed")}
        >
          Зөвшөөрөхгүй
        </Button> */}
        <Button
          icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
          onClick={() => navigate("/thank-you")}
          textSize="md"
          className="w-full"
        >
          Дуусгах
        </Button>
      </div>
    </div>
  );
};

export default Invoice;
