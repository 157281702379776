import dayjs from 'dayjs';
import { useState } from "react";
import { Outlet } from "react-router";

import { useStore } from "../../store";
import Typography from "../../base-components/Typography";
import CircularProgress from "../../base-components/CircularProgress";
import Button from "../../base-components/Button";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

const XypNotAllowed = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setObject = useStore.useSetObject();
  const isXyp = useStore.useIsXyp();

  const methods = useForm({
    last_name: '',
    name: '',
    registry_number: '',
    mobile: '',
    address: '',
    vin: '',
    plate_number: '',
    make: -1,
    model: -1,
    color: -1,
    year: -1,
    imported_date: '',
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const onSubmit = (data) => {
    const object_data = {...data}
    console.log(location.pathname, 'path');
    if(location.pathname === '/user-info') {
      if(isXyp) {
        setObject(object_data);
        navigate('/xyp-vehicle-info'); 
      } else {
        navigate('/vehicle-info');
      }
    } else {
      object_data['imported_date'] = dayjs(data?.imported_date).format('YYYY-MM-DD');
      setObject(object_data);
      navigate('/confirm-info');
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Outlet />
        <div className="mt-4 flex space-x-4 py-4">  {/* absolute bottom-4 -inset-x-[.5px] py-6 px-4 border border-b-0 border-dark-12 rounded-t-2xl bg-white/[0.04] */}
          <div className="relative inline-flex">
            <div className="absolute bg-dark-12 p-[2px] rounded-full w-12 h-12">
              <div className="w-full h-full rounded-full bg-gray flex justify-center items-center">
                <Typography variant="Title" size="sm">1/2</Typography>
              </div>
            </div>
            <CircularProgress />
          </div>
          <Button
            disabled={isDisabled}
            icon={
              <Arrow fill={isDisabled ? '#B3C0D0' : "#fff"} className="w-[18px] h-[18px]" />
            }
            className="grow"
            type="submit"
          >
            Үргэлжлүүлэх
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default XypNotAllowed;