import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";
import DamageService from "../../services/DamageService";
import Button from "../../base-components/Button";

const Image = () => {
  const navigate = useNavigate();

  const image = useStore.useImage();
  const setImage = useStore.useSetImage();

  function handleContinue() {
    const payload = {}
    payload['front'] = image
    DamageService.save(payload)
      .then((response) => {
        if (response === "success")
          navigate('/damages');
      })
  }

  return (
    <>
      {
        image &&
        <div className="absolute inset-0 p-4 py-6 bg-gray space-y-4">
          <div className="flex justify-center border border-secondary-12 rounded-lg bg-white h-52">
            <img src={image} alt="damage" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button variant="primary-outlined" onClick={() => {
              setImage('')
              navigate('/damages');
            }}>
              Дахин дарах
            </Button>
            <Button onClick={() => handleContinue()}>
              Үргэлжлүүлэх
            </Button>
          </div>
        </div>
      }
    </>
  )
}

export default Image;