import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import useStore2 from "../../store/hook";
import { formatXypDate, stringValue } from "../../utils/helper";
import ObjectInfoService from "../../services/ObjectInfoService";
import Button from "../../base-components/Button";
import Input from "../../base-components/Input";
import Typography from "../../base-components/Typography";
import Card from "../../components/Card";
import Tile from "../../components/Tile";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";
import { ReactComponent as Car } from "../../assets/icons/car-1.svg";
import { ReactComponent as SadRobot } from "../../assets/icons/sad-robot.svg";
import { useNavigate } from "react-router";
import { iVehicle } from "../../store/objectSlice";
import EstimationService from "../../services/estimation/EstimationService";

interface XypVehicleInfoProps {
  plate_number?: string;
  cabin_number?: string;
  vin?: string;
}

const XypVehicleInfo = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      plate_number: "",
      vin: "",
    },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    // watch: watch2,
    formState: { errors: errors2 },
  } = useForm({
    defaultValues: {
      vin: "",
      plateNumber: "",
      make: "",
      model: "",
      color: "",
      year: "",
      importDate: "",
    },
  });

  const vehicleInfo = useStore2.useVehicleInfo();
  const setVehicleInfo = useStore2.useSetVehicleInfo();
  const setName = useStore2.useSetName();
  // const object = useStore2.useObject();
  const [xypSuccess, setXypSuccess] = useState<null | boolean>(null);
  const [dataAvailable, setDataAvailable] = useState<boolean>(true);

  // Hur-s tatah
  const onSubmitXyp = (data: XypVehicleInfoProps) => {
    const params: XypVehicleInfoProps = {};
    if (!!data.plate_number) params["plate_number"] = data.plate_number;
    if (!!data.vin) params["cabin_number"] = data.vin;
    ObjectInfoService.vehicleInfo(params)
      .then((response) => {
        setVehicleInfo({
          ...response?.vehicle_data,
          importDate: formatXypDate(response?.vehicle_data?.importDate),
          vin: response?.vehicle_data?.cabinNumber,
          make: response?.vehicle_data?.markName,
          model: response?.vehicle_data?.modelName,
          color: response?.vehicle_data?.colorName,
          year: response?.vehicle_data?.buildYear,
        });
        setXypSuccess(true);
        EstimationService.CheckData({
          buildYear: response?.vehicle_data?.buildYear,
          modelName: response?.vehicle_data?.modelName,
          markName: response?.vehicle_data?.markName,
          vin: response?.vehicle_data?.cabinNumber,
        }).then((response) => {
          if (response.status === false) {
            setDataAvailable(false);
          } else {
            setDataAvailable(true);
          }
        });
      })
      .catch((error) => {
        setXypSuccess(false);
      });
  };

  const onFinish = () => {
    if (!!vehicleInfo?.plateNumber) {
      console.log(vehicleInfo.plateNumber);
      setName(vehicleInfo.plateNumber);
      navigate("/damages");
    } else {
      alert("Улсын дугаар оруулаагүй байна!");
    }
  };

  const onSubmitVehicleInfo = (data: iVehicle) => {
    console.log(data);
    setVehicleInfo({
      ...data,
      importDate: dayjs(data?.importDate).format("YYYY-MM-DD"),
    });
    onFinish();
  };

  const saveXypInfo = () => {
    onFinish();
  };

  return (
    <Fragment>
      <Typography variant="Title" size="md" className="w-72">
        Хохирогчийн тээврийн хэрэгсэлийн мэдээллийг оруулна уу?
      </Typography>
      <Card>
        <form onSubmit={handleSubmit(onSubmitXyp)} className={"space-y-4"}>
          <div>
            <Input
              label="Улсын дугаар"
              className="w-full"
              disabled={!!watch("vin", "")}
              {...register("plate_number", {
                required: !watch("vin", ""),
                // pattern: !watch("vin", "")
                //   ? /^([0-9]{4})([А-ЯЁӨҮ]{3})$/
                //   : false,
              })}
            />
            {errors?.plate_number?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Улсын дугаараа оруулна уу.
              </p>
            )}
            {errors?.plate_number?.type === "pattern" && (
              <p className="text-error text-xs pl-2 mt-1">
                Улсын дугаар буруу байна.
              </p>
            )}
          </div>
          <div className="flex items-center justify-center relative">
            <span className="border-t border-dark-12 border-dashed inset-x-0 absolute"></span>
            <Typography size="md" className="text-black-64 bg-white z-10 px-2">
              эсвэл
            </Typography>
            <span></span>
          </div>
          <div>
            <Input
              label="Арлын дугаар"
              className="w-full"
              disabled={!!watch("plate_number", "")}
              {...register("vin", {
                required: !watch("plate_number", ""),
              })}
            />
            {errors?.vin?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Арлын дугаараа оруулна уу.
              </p>
            )}
          </div>
          <Button
            className="w-full"
            disabled={!watch("plate_number", "") && !watch("vin", "")}
          >
            ХУР-аас татах
          </Button>
        </form>
      </Card>
      {dataAvailable === false ? (
        <div className="flex flex-col items-center gap-4">
          {/* no data sorry */}
          <SadRobot />
          <Typography className="text-center" variant="Body">
            Уучлаарай, энэ удаад би таны машин дээр үнэлгээ хийж чадсангүй,
            дараагийн удаа би улам хөгжих болно оо.
          </Typography>
        </div>
      ) : (
        <>
          {vehicleInfo && (
            <Fragment>
              <Card>
                <header className="flex items-center space-x-3">
                  <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
                    <Car fill="#E3A13A" className="w-4 h-4" />
                  </div>
                  <Typography variant="Title" size="md">
                    Тээврийн хэрэгслийн мэдээлэл
                  </Typography>
                </header>
                <div className="py-2">
                  <Tile
                    field={"Арлын дугаар"}
                    value={stringValue(vehicleInfo?.vin)}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 gap-y-2">
                  <Tile
                    field={"Улсын дугаар"}
                    value={stringValue(vehicleInfo?.plateNumber)}
                  />
                  <Tile
                    field={"Үйлдвэрлэгч"}
                    value={stringValue(vehicleInfo?.make)}
                  />
                  <Tile
                    field={"Модел"}
                    value={stringValue(vehicleInfo?.model)}
                  />
                  <Tile
                    field={"Өнгө"}
                    value={stringValue(vehicleInfo?.color)}
                  />
                  <Tile
                    field={"Үйлдвэрлэсэн он"}
                    value={stringValue(vehicleInfo?.year)}
                    className="border-none"
                  />
                  <Tile
                    field={"Орж ирсэн огноо"}
                    value={stringValue(vehicleInfo?.importDate)}
                    className="border-none"
                  />
                </div>
              </Card>
              <div className="mt-4 flex space-x-4 py-4">
                <Button
                  icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
                  onClick={() => saveXypInfo()}
                  className="w-full"
                >
                  Баталгаажуулах
                </Button>
              </div>
            </Fragment>
          )}
          {/* {xypSuccess === false && (
            <Card>
              <header className="flex items-center space-x-3">
                <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
                  <Car fill="#E3A13A" className="w-4 h-4" />
                </div>
                <Typography variant="Title" size="md">
                  Тээврийн хэрэгслийн мэдээлэл
                </Typography>
              </header>
              <form onSubmit={handleSubmit2(onSubmitVehicleInfo)}>
                <div className="py-2">
                  <Input
                    label="Арлын дугаар"
                    className="w-full"
                    // value={stringValue(vehicleInfo?.cabinNumber)}
                    {...register2("vin", {
                      required: true,
                    })}
                  />
                  {errors2?.vin?.type === "required" && (
                    <p className="text-xs text-error font-semibold mt-1 ml-1">
                      Арлын дугаар оруулна уу.
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4 gap-y-2">
                  <div>
                    <Input
                      label="Улсын дугаар"
                      // value={stringValue(vehicleInfo?.plateNumber)}
                      {...register2("plateNumber", {
                        required: true,
                      })}
                    />
                    {errors2?.plateNumber?.type === "required" && (
                      <p className="text-xs text-error font-semibold mt-1 ml-1">
                        Улсын дугаар оруулна уу.
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Үйлдвэрлэгч"
                      // value={stringValue(vehicleInfo?.markName)}
                      {...register2("make", {
                        required: true,
                      })}
                    />
                    {errors2?.make?.type === "required" && (
                      <p className="text-xs text-error font-semibold mt-1 ml-1">
                        Үйлдвэрлэгч оруулна уу.
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Модел"
                      // value={stringValue(vehicleInfo?.modelName)}
                      {...register2("model", {
                        required: true,
                      })}
                    />
                    {errors2?.model?.type === "required" && (
                      <p className="text-xs text-error font-semibold mt-1 ml-1">
                        Модел оруулна уу.
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Өнгө"
                      // value={stringValue(vehicleInfo?.colorName)}
                      {...register2("color", {
                        required: true,
                      })}
                    />
                    {errors2?.color?.type === "required" && (
                      <p className="text-xs text-error font-semibold mt-1 ml-1">
                        Өнгө оруулна уу.
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Үйлдвэрлэсэн он"
                      // value={stringValue(vehicleInfo?.buildYear)}
                      className="border-none"
                      {...register2("year", {
                        required: true,
                      })}
                    />
                    {errors2?.year?.type === "required" && (
                      <p className="text-xs text-error font-semibold mt-1 ml-1">
                        Үйлдвэрлэсэн он дугаар оруулна уу.
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Орж ирсэн огноо"
                      // value={stringValue(vehicleInfo?.importDate)}
                      className="border-none"
                      {...register2("importDate", {
                        required: true,
                      })}
                    />
                    {errors2?.importDate?.type === "required" && (
                      <p className="text-xs text-error font-semibold mt-1 ml-1">
                        Орж ирсэн огноо оруулна уу.
                      </p>
                    )}
                  </div>
                  <Button
                    icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
                    className="w-full col-span-2"
                  >
                    Баталгаажуулах
                  </Button>
                </div>
              </form>
            </Card>
          )} */}
        </>
      )}
    </Fragment>
  );
};

export default XypVehicleInfo;
