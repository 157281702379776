import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";
import { parseValue, stringValue } from "../../utils/helper";
import PolicyInfoService from "../../services/PolicyInfoService";
import { ReactComponent as ShieldCheck } from "../../assets/icons/shield-check-1.svg";
import { ReactComponent as Verified } from "../../assets/icons/verified-1.svg";
import { ReactComponent as User } from "../../assets/icons/user-1.svg";
import { ReactComponent as Car } from "../../assets/icons/car-1.svg";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";
import Typography from "../../base-components/Typography";
import Card from "../../components/Card";
import Button from "../../base-components/Button";
import Tile from "../../components/Tile";

const InsuredInfo = () => {
  const navigate = useNavigate();

  const token = useStore.useToken();
  const [policy, setPolicy] = useState({});

  useEffect(() => {
    if (token !== null) {
      PolicyInfoService.getPolicyInfo().then((response) => {
        setPolicy(response.data);
      });
    }
  }, [token]);

  return (
    <Fragment>
      <Typography variant="Title" size="md">
        Та даатгуулагчийн мэдээллээ баталгаажуулаарай
      </Typography>
      <Card className={"my-4 pt-6"}>
        <header className="flex items-center space-x-3">
          <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
            <ShieldCheck fill="#E3A13A" className="w-4 h-4" />
          </div>
          <Typography variant="Title" size="md">
            Даатгалын мэдээлэл
          </Typography>
        </header>
        <div className="mt-6">
          <div className="bg-gradient-to-r from-[rgba(255,177,72,1)] to-[rgba(2,96,183,1)] p-[1px] rounded-lg w-fit">
            <div className="px-3 py-2 space-x-2 flex items-center rounded-lg bg-white">
              <Verified fill="#E2A95C" className="h-[18px] w-[18px]" />
              <Typography variant="Title" size="sm" className="text-secondary">
                Идэвхитэй
              </Typography>
            </div>
          </div>
          <div className="mt-4">
            <Tile
              field="Гэрээний дугаар"
              value={stringValue(policy.policy_info?.warranty_number)}
            />
            <Tile
              field="Дуусах огноо"
              value={policy.policy_info?.expiry_datetime}
            />
            <Tile
              field="Бүтээгдэхүүн"
              value={stringValue(parseValue(policy.policy_info?.product_id))}
              className="border-none"
            />
          </div>
        </div>
      </Card>
      <Card className={"my-4 pt-6"}>
        <header className="flex items-center space-x-3">
          <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
            <User fill="#E3A13A" className="w-4 h-4" />
          </div>
          <Typography variant="Title" size="md">
            Даатгуулагчийн мэдээлэл
          </Typography>
        </header>
        <div className="mt-4">
          <Tile
            field="Овог"
            value={stringValue(policy.partner_info?.lastname)}
          />
          <Tile field="Нэр" value={stringValue(policy.partner_info?.name)} />
          <Tile
            field="Регистерийн дугаар"
            value={stringValue(policy.partner_info?.registry_number)}
          />
          <Tile field="Утас" value={stringValue(policy.partner_info?.mobile)} />
          <Tile
            field="Гэрийн хаяг"
            value={stringValue(policy.partner_info?.contact_address)}
            className="border-none"
          />
        </div>
      </Card>
      {"x_vehicle" === policy.dynamic_table_name && (
        <Card className={"my-4 pt-6"}>
          <header className="flex items-center space-x-3">
            <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
              <Car fill="#E3A13A" className="w-4 h-4" />
            </div>
            <Typography variant="Title" size="md">
              Тээврийн хэрэгслийн мэдээлэл
            </Typography>
          </header>
          <div className="mt-4">
            <div className="py-2">
              <Tile
                field={"Арлын дугаар"}
                value={stringValue(policy.object_info?.vin)}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 gap-y-2">
              <Tile
                field={"Улсын дугаар"}
                value={stringValue(policy.object_info?.plate_number)}
              />
              <Tile
                field={"Үйлдвэрлэгч"}
                value={stringValue(parseValue(policy.object_info?.make_id))}
              />
              <Tile
                field={"Модел"}
                value={stringValue(parseValue(policy.object_info?.model_id))}
              />
              <Tile
                field={"Өнгө"}
                value={stringValue(parseValue(policy.object_info?.color_id))}
              />
              <Tile
                field={"Үйлдвэрлэсэн он"}
                value={stringValue(parseValue(policy.object_info?.year_id))}
                className="border-none"
              />
              <Tile
                field={"Орж ирсэн огноо"}
                value={stringValue(policy.object_info?.imported_date)}
                className="border-none"
              />
            </div>
          </div>
        </Card>
      )}
      <Button
        onClick={() =>
          "x_vehicle" === policy.dynamic_table_name
            ? navigate("/case")
            : navigate("/xyp-confirm")
        }
        icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
        className="w-full my-6"
      >
        Баталгаажуулах
      </Button>
    </Fragment>
  );
};

export default InsuredInfo;
