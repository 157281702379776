import { forwardRef, useState, Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Transition } from "@headlessui/react";
import { ReactComponent as Success } from "../../assets/icons/fi-sr-check-circle-filled.svg";
import { ReactComponent as Info } from "../../assets/icons/fi-rr-interrogation.svg";
import { ReactComponent as Warning } from "../../assets/icons/info.svg";
import { ReactComponent as Error } from "../../assets/icons/fi-rr-cross-circle.svg";
import Typography from "../Typography";

const Alert = forwardRef(({ as, dismissible, variant, ...props }, ref) => {
  const [show, setShow] = useState(true);
  const Component = as || "div";

  // Main Colors
  const primary = [
    "bg-primary border-primary text-white", // Default
    "dark:border-primary", // Dark
  ];
  const secondary = [
    "bg-secondary/70 border-secondary/70 text-slate-500", // Default
    "dark:border-darkmode-400 dark:bg-darkmode-400 dark:text-slate-300", // Dark mode
  ];
  const success = [
    "bg-success border-success text-slate-900", // Default
    "dark:border-success", // Dark mode
  ];
  const warning = [
    "bg-[#FCF4E7] border border-secondary text-slate-900", // Default
    "dark:border-warning", // Dark mode
  ];
  const pending = [
    "bg-pending border-pending text-white", // Default
    "dark:border-pending", // Dark mode
  ];
  const danger = [
    "bg-danger border-danger text-white", // Default
    "dark:border-danger", // Dark mode
  ];

  return (
    <Transition
      as={Fragment}
      show={show}
      enter="transition-all ease-linear duration-150"
      enterFrom="invisible opacity-0 translate-y-1"
      enterTo="visible opacity-100 translate-y-0"
      leave="transition-all ease-linear duration-150"
      leaveFrom="visible opacity-100 translate-y-0"
      leaveTo="invisible opacity-0 translate-y-1"
    >
      <Component
        {...props}
        ref={ref}
        role="alert"
        className={twMerge([
          "relative border rounded-lg p-4 flex space-x-2",
          variant === "primary" && primary,
          variant === "secondary" && secondary,
          variant === "success" && success,
          variant === "warning" && warning,
          variant === "pending" && pending,
          variant === "danger" && danger,
          dismissible && "pl-5 pr-16",
          props.className,
        ])}
      >
        <div className="inline-flex pt-[2px]">
          {variant === "success" && <Success className="w-3.5 h-3.5" />}
          {variant === "warning" && (
            <Warning fill="#E3A13A" className="w-3.5 h-3.5" />
          )}
          {variant === "info" && <Info className="w-3.5 h-3.5" />}
          {variant === "error" && <Error className="w-3.5 h-3.5" />}
        </div>
        <Typography size="sm">
          {typeof props.children === "function"
            ? props.children({
                dismiss: () => {
                  setShow(false);
                },
              })
            : props.children}
        </Typography>
      </Component>
    </Transition>
  );
});

const DismissButton = ({ as, children, ...props }) => {
  const Component = as || "button";

  return (
    <Component
      {...props}
      type="button"
      aria-label="Close"
      className={twMerge([
        "text-slate-800 py-2 px-3 absolute right-0 my-auto mr-2",
        props.className,
      ])}
    >
      {children}
    </Component>
  );
};

const AlertComponent = Object.assign({}, Alert, {
  DismissButton: DismissButton,
});

export default AlertComponent;
