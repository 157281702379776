import { StateMachineProvider } from "little-state-machine";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/layout";
import Home from "./pages/home";
import Cases from "./pages/cases";
import Camera from "./pages/camera";
import CameraMask from "./pages/camera-mask";
import Vin from "./pages/vin";
import Damages from "./pages/damages";
import Progress from "./pages/progress";
import SecondaryLayout from "./layouts/secondary";
import Result from "./pages/result";
import Calculations from "./pages/calculations";
import Invoice from "./pages/invoice";
import DriverInfo from "./pages/driver-info";
import AccountInfo from "./pages/account-info";
import ApplicationForm from "./pages/application-form";
import Signature from "./pages/signature";
import Summary from "./pages/summary";
import Successful from "./pages/successful";
import InsuredInfo from "./pages/insured-info";
import Image from "./pages/image";
import XypConfirm from "./pages/xyp-confirm";
import XypVehicleInfo from "./pages/xyp-vehicle-info";
import XypVictimInfo from "./pages/xyp-victim-info";
import XypNotConfirm from "./pages/xyp-not-confirm";
import UserInfo from "./pages/xyp-not-confirm/user-info";
import VehicleInfo from "./pages/xyp-not-confirm/vehicle-info";
import ConfirmInfo from "./pages/xyp-not-confirm/confirm-info";
import DamageInstructions from "./pages/damages/instructions";
import PriceNotAllowed from "./pages/price-not-allowed";
import Warning from "./pages/price-not-allowed/warning";
import Reason from "./pages/price-not-allowed/reason";
import Contact from "./pages/price-not-allowed/contact";
import Victim from "./pages/victim/index.tsx";
import ThankYou from "./pages/thank-you";

function App() {
  return (
    <StateMachineProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/victim" element={<Victim />} />
            <Route path="/insured-info" element={<InsuredInfo />} />
            <Route path="/xyp-confirm" element={<XypConfirm />} />
            <Route path="/xyp-vehicle-info" element={<XypVehicleInfo />} />
            <Route path="/xyp-victim-info" element={<XypVictimInfo />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/" element={<XypNotConfirm />}>
              <Route path="/user-info" element={<UserInfo />} />
              <Route path="/vehicle-info" element={<VehicleInfo />} />
            </Route>
            <Route path="/confirm-info" element={<ConfirmInfo />} />
            <Route path="/case" element={<Cases />}>
              <Route path=":mode" element={<Cases />} />
            </Route>
            <Route path="/vin" element={<Vin />}>
              <Route path=":mode" element={<Vin />} />
            </Route>
            <Route path="/damages" element={<Damages />} />
            <Route
              path="/take-photos-instructions"
              element={<DamageInstructions />}
            />
            <Route path="camera-mask" element={<CameraMask />}>
              <Route path=":location" element={<CameraMask />} />
            </Route>
            <Route path="camera" element={<Camera />} />
            <Route path="image" element={<Image />} />
          </Route>
          <Route path="/" element={<SecondaryLayout />}>
            <Route path="/progress" element={<Progress />} />
            <Route path="/result" element={<Result />} />
            <Route path="/calculations" element={<Calculations />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/price-not-allowed" element={<PriceNotAllowed />}>
              <Route path="" element={<Reason />} />
              <Route path="warning" element={<Warning />} />
              <Route path="contact" element={<Contact />} />
            </Route>
            <Route path="/driver-info" element={<DriverInfo />} />
            <Route path="/account-info" element={<AccountInfo />} />
            <Route path="/application-form" element={<ApplicationForm />} />
            <Route path="/signature" element={<Signature />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/successful" element={<Successful />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StateMachineProvider>
  );
}

export default App;
