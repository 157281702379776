import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { createSelectorHooks } from "auto-zustand-selectors-hook";

import createVictimSlice, { iVictim } from "./victimSlice";
import createDamagedSlice, { iDamage } from "./damageSlice";
import createCameraSlice, { iIMage } from "./cameraSlice";
import createObjectSlice, { iObject } from "./objectSlice";
import createDamagedPartsSlice, { iDamagedParts } from "./damagedPartsSlice";
import createEstimationSlice, { iEstimation } from "./estimationSlice";

const useStore2Base = create(
  devtools(
    persist<iVictim & iDamage & iIMage & iObject & iDamagedParts & iEstimation>(
      (...a) => ({
        ...createVictimSlice(...a),
        ...createDamagedSlice(...a),
        ...createCameraSlice(...a),
        ...createObjectSlice(...a),
        ...createDamagedPartsSlice(...a),
        ...createEstimationSlice(...a),
      }),
      {
        name: "ai-store-2",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
const useStore2 = createSelectorHooks(useStore2Base);
export default useStore2;
