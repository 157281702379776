import { useStore } from '../../../store';
import ObjectInfoService from '../../../services/ObjectInfoService';
import { stringValue } from '../../../utils/helper';
import Typography from '../../../base-components/Typography';
import Card from '../../../components/Card';
import { ReactComponent as User } from '../../../assets/icons/user-1.svg';
import { ReactComponent as Car } from '../../../assets/icons/car-1.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit-3.svg';
import { ReactComponent as Check } from '../../../assets/icons/check-circle-2-1.svg';
import Tile from '../../../components/Tile';
import Button from '../../../base-components/Button';
import { useNavigate } from 'react-router';

const ConfirmInfo = () => {
  const navigate = useNavigate();

  const object = useStore.useObject();

  const saveObject = () => {
    const payload = { 
      ...object,
      'make_id': object?.make?.id,
      'model_id': object?.model?.id,
      'color_id': object?.color?.id,
      'year_id': object?.year?.id,
    }

    ObjectInfoService.save(payload)
      .then((response) => {
        if (response === 'success')
          navigate('/case')
      });
  }

  return (
    <>
      <Typography variant='Title' size='md' className='w-48'>
        Та даатгуулагчийн мэдээллээ
        баталгаажуулаарай
      </Typography>
      <Card className={'my-8'}>
        <header className='flex items-center space-x-3 mt-2'>
          <div className='inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12'>
            <User fill='#E3A13A' className='w-4 h-4' />
          </div>
          <Typography variant='Title' size='md'>
            Даатгуулагчийн мэдээлэл
          </Typography>
        </header>
        <div className='mt-4'>
          <Tile field='Овог' value={stringValue(object?.last_name)}/>
          <Tile field='Нэр' value={stringValue(object?.name)}/>
          <Tile field='Регистерийн дугаар' value={stringValue(object?.registry_number)}/>
          <Tile field='Утас' value={stringValue(object?.mobile)}/>
          <Tile field='Гэрийн хаяг' value={stringValue(object?.address)} className='border-none' />
        </div>
        <Button
          variant='primary-outlined'
          icon={<Edit className='w-[18px] h-[18px]' />}
          className='w-full'
        >
          Засах
        </Button>
      </Card>
      <Card className={'my-4 pt-6'}>
        <header className='flex items-center space-x-3'>
          <div className='inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12'>
            <Car fill='#E3A13A' className='w-4 h-4' />
          </div>
          <Typography variant='Title' size='md'>
            Тээврийн хэрэгслийн мэдээлэл
          </Typography>
        </header>
        <div className='mt-4'>
          <div className='py-2'>
            <Tile field='Арлын дугаар' value={object?.vin}/>
          </div>
          <div className='grid grid-cols-2 gap-4 gap-y-2'>
            <Tile field='Улсын дугаар' value={stringValue(object?.plate_number)}/>
            <Tile field='Үйлдвэрлэгч' value={stringValue(object?.make?.name)}/>
            <Tile field='Модел' value={stringValue(object?.model?.name)} />
            <Tile field='Өнгө' value={stringValue(object?.color?.name)} />
            <Tile field='Үйлдвэрлэсэн он' value={stringValue(object?.year?.name)} className='border-none' />
            <Tile field='Орж ирсэн огноо' value={stringValue(object?.imported_date)} className='border-none' />
          </div>
        </div>
        <Button
          variant='primary-outlined'
          icon={<Edit className='w-[18px] h-[18px]' />}
          className='w-full'
        >
          Засах
        </Button>
      </Card>
      <Button
        onClick={() => saveObject()}
        icon={
          <Check fill='#fff' className='w-[18px] h-[18px]' />
        }
        className='w-full my-6'
      >
        Баталгаажуулах
      </Button>
    </>
  )
}

export default ConfirmInfo;