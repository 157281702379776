import { Fragment, useEffect, useState } from 'react'
import { Transition, Combobox } from '@headlessui/react'
import { ClipLoader } from "react-spinners";
import { twMerge } from 'tailwind-merge';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { isNullOrUndefined } from '../../utils/helper';
import PropTypes from "prop-types";
import Empty from '../Empty';

function Select(props) {
  const {
    label,
    id,
    value,
    defaultValue,
    className,
    optionClassName,
    onChange,
    options = [],
    placeholder = "",
    service,
    params,

  } = props;
  const [isShow, setIsShow] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  /* SERVICE */

  useEffect(() => {
    if (!isShow) return;
    if (service) {
      setLoading(true);
      service(params)
        .then((response) => {
          if (response.values?.length > 0) {
            if (id === `selection_year_id`) {
              let tmpList = [];
              response.values.forEach((data) => {
                tmpList.push({
                  id: data.id,
                  name: data.year
                })
              })
              if (tmpList.length > 0)
                setItems([...tmpList]);
            }
            else {
              if (response.values.length > 0)
                setItems([...response.values]);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("select service error", error);
        })
    }
  }, [isShow])

  useEffect(() => {
    if (options.length > 0)
      setItems([...options]);
  }, [options])

  const handleKeyDown = (e) => {
    switch (e.code) {
      // case "NumpadEnter":
      // case "Enter":
      //   e.preventDefault();
      //   handleOnChange(selected);
      //   break;
      case "Space":
        e.preventDefault();
        setIsShow(prev => !prev);
        break;
      case "Escape":
        setIsShow(false);
        break;
      default:
        break;
    }
  }
  const searchValue = (e) => {
    setSearchedValue(e.target.value);
    if (!isShow)
      setIsShow(true);
  }
  const handleOnChange = (value) => {
    if (!!onChange)
      onChange(value);
    setIsShow(false);
  }
  const filterList =
    searchedValue === ""
      ? items
      : items.filter((item) =>
        item.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(searchedValue.toLowerCase().replace(/\s+/g, ''))
      )

  return (
    <Combobox value={value} defaultValue={defaultValue} onChange={handleOnChange} className='relative cursor-pointer'>
      <div className='relative cursor-pointer'>
        <Combobox.Label
          className={twMerge([
            'absolute top-0 left-0 z-10 translate-x-4 translate-y-4 text-sm text-[#465972] transition-all duration-300 pointer-events-none',
            (!isNullOrUndefined(value?.name) || searchedValue !== "") && 'translate-y-1 text-xs',
            value?.name === false && 'translate-y-4 text-sm',
          ])}
        >
          {label}
        </Combobox.Label>
        <Combobox.Button
          onClick={() => setIsShow(prev => !prev)}
          onBlur={() => setIsShow(false)}
          className={twMerge([
            'relative transition duration-200 ease-in-out w-full outline-none bg-white',
            isShow && 'border-black-64',
          ])}>
          <div className='flex justify-between items-center'>
            <Combobox.Input
              onChange={searchValue}
              onKeyDown={handleKeyDown}
              displayValue={(item) => item.name}
              placeholder={placeholder}
              className={twMerge([
                "grow block truncate outline-none w-full p-4 rounded-lg font-medium tracking-wide text-sm text-primary font-semibold border-dark-12 border shadow-sm rounded-lg",
                className,
              ])}
              autoComplete='off'
            />
            <ChevronDown
              className={twMerge(["h-5 w-5 text-gray-400 absolute right-4 pointer-events-none", isShow && "rotate-180"])}
              aria-hidden="true"
            />
          </div>
        </Combobox.Button>
        <Transition
          as={Fragment}
          show={isShow}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Combobox.Options
            className={
              twMerge([
                'absolute bg-white w-full left-0 mt-2 rounded-lg p-2 shadow-bottom-sheet animation-all max-h-56 overflow-y-auto overflow-x-hidden z-50', //border border-on-surface-black-64 
                // className,
              ])}>
            {
              filterList.length === 0 && loading ?
                (
                  <div className='p-8 flex justify-center'>
                    <ClipLoader color="#C0CBD8" size={25} />
                  </div>
                ) :
                filterList.length === 0 ?
                  (
                    <Empty className="text-sm p-8" />
                  ) :
                  (
                    filterList?.map((option, index) =>
                      <Combobox.Option
                        id={`option-id-${index}`}
                        key={option.id}
                        className={({ selected, active }) =>
                          twMerge([
                            'p-2.5 flex items-center justify-between text-black-64 rounded-lg',
                            active && "text-primary bg-surface-gray", // HOVER,
                            selected && 'bg-third-soft',
                            optionClassName,
                          ])}
                        value={option}
                      >
                        {option.name}
                      </Combobox.Option>
                    ))
            }
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  service: PropTypes.func,
  optionClassName: PropTypes.string,
}

export default Select;