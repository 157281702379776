import { twMerge } from "tailwind-merge"
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";
import IconButton from "../../base-components/IconButton";

const SidesButton = ({ className, isIncluded, onClick }) => {
  return (
    <div className={twMerge(["absolute", className])}>
      <IconButton variant={isIncluded ? "secondary-contained" : "primary-outlined"} className="border-dark-12" onClick={onClick}>
        {
          isIncluded ?
            <Check fill="#fff" /> :
            <Plus />
        }
      </IconButton>
    </div>
  )
}

export default SidesButton;