import { forwardRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

interface MuiAccordionProps {
  children: NonNullable<React.ReactNode>;
}

const MuiAccordion = forwardRef<HTMLDivElement, MuiAccordionProps>(
  ({ children, ...props }, ref) => {
    return (
      <div>
        <Accordion
          ref={ref}
          {...props}
          sx={{
            boxShadow: "none",
            padding: "8px 0",
            "& .MuiButtonBase-root": {
              padding: 0,
            },
          }}
        >
          {children}
        </Accordion>
      </div>
    );
  }
);

interface MuiAccordionHeaderProps {
  expandIcon?: React.ReactNode;
  children: React.ReactNode;
}

export const MuiAccordionHeader = ({
  expandIcon,
  ...props
}: MuiAccordionHeaderProps) => {
  return (
    <AccordionSummary
      {...props}
      sx={{
        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
      }}
      expandIcon={expandIcon}
    >
      {props.children}
    </AccordionSummary>
  );
};

// interface MuiAccordionDetailsProps {
//   className?: string;
//   sx?: any;
//   children: React.ReactNode;
// }

export const MuiAccordionDetails = ({ ...props }) => {
  return (
    <AccordionDetails {...props} sx={{ p: 0 }}>
      {props.children}
    </AccordionDetails>
  );
};

export default MuiAccordion;
