import { twMerge } from "tailwind-merge";
import { ReactComponent as Inbox } from "../../assets/icons/inbox.svg";

function Empty(props) {
  const {
    value = "Өгөгдөл байхгүй байна",
    className
  } = props

  return (
    <div className={twMerge(["text-[#c1c7cd] text-center", className])}>
      <div className="flex justify-center mb-4">
        <Inbox stroke="#c1c7cd" className="w-8 h-8" />
      </div>
      <div className="text-[#c1c7cd]">
        {value}
      </div>
    </div>
  )
}

export default Empty;