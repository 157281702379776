import { useFormContext } from "react-hook-form";
import Typography from "../../../base-components/Typography"
import Card from "../../../components/Card"
import Input from "../../../base-components/Input";
import { ReactComponent as User } from "../../../assets/icons/user-1.svg";

const CYRILLIC_NAME = /^[А-ЯЁӨҮ-]+$/i;

const UserInfo = () => {
  const { register, formState: { errors } } = useFormContext(
    {
      last_name: '',
      name: '',
      registry_number: '',
      mobile: '',
      address: '',
    }
  );

  return (
    <>
      <Typography variant="Title" size="md" className="w-48">
        Та өөрийн мэдээллийг
        бичиж оруулна уу
      </Typography>
      <Card className={"my-8"}>
        <header className="flex items-center space-x-3 mt-2">
          <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
            <User fill="#E3A13A" className="w-4 h-4" />
          </div>
          <Typography variant="Title" size="md">
            Даатгуулагчийн мэдээлэл
          </Typography>
        </header>
        <div className="space-y-3 mt-4">
          <div>
            <Input
              label="Овог"
              className="w-full"
              {...register('last_name', {
                required: true,
                pattern: CYRILLIC_NAME,
              })}
            />
            {
              errors?.lastname?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Овгоо оруулна уу.</p>
            }
            {
              errors?.last_name?.type === "pattern" &&
              <p className="text-error text-xs pl-2 mt-1">Крилл үсгээр бичнэ үү!!!</p>
            }
          </div>
          <div>
            <Input
              label="Нэр"
              className="w-full"
              {...register('name', {
                required: true,
                pattern: CYRILLIC_NAME,
              })}
            />
            {
              errors?.name?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Нэрээ оруулна уу.</p>
            }
            {
              errors?.name?.type === "pattern" &&
              <p className="text-error text-xs pl-2 mt-1">Крилл үсгээр бичнэ үү!!!</p>
            }
          </div>
          <div>
            <Input
              label="Регистерийн дугаар"
              className="w-full"
              {...register('registry_number', {
                required: true,
                pattern: /^([А-ЯЁӨҮ]{2})([0-9]{8})$/i,
              })}
            />
            {
              errors?.registry_number?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Регистрийн дугаараа оруулна уу.</p>
            }
            {
              errors?.registry_number?.type === "pattern" &&
              <p className="text-error text-xs pl-2 mt-1">Регистрийн дугаар буруу байна.</p>
            }
          </div>
          <div>
            <Input
              label="Утас"
              className="w-full"
              {...register('mobile', {
                required: true,
                pattern: /^[0-9]{8}$/,
              })}
            />
            {
              errors?.mobile?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Утасны дугаараа оруулна уу.</p>
            }
            {
              errors?.mobile?.type === "pattern" &&
              <p className="text-error text-xs pl-2 mt-1">Утасны дугаар буруу байна.</p>
            }
          </div>
          <div>
            <Input
              label="Гэрийн хаяг"
              className="w-full"
              {...register('address', {
                required: true,
              })}
            />
            {
              errors?.address?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Гэрийн хаягаа оруулна уу.</p>
            }
          </div>
        </div>
      </Card>
    </>
  )
}

export default UserInfo;