import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { isEmptyObject } from "../../utils/helper";
import LicenseCertService from "../../services/LicenseCertService";
import Card from "../../components/Card";
import ImportImage, { imageMimeType } from "../../components/ImportImage";
import Typography from "../../base-components/Typography";
import Button from "../../base-components/Button";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";

const DriverInfo = () => {
  const navigate = useNavigate();

  const [images, setImages] = useState({});

  useEffect(() => {
    LicenseCertService.get()
      .then((response) => {
        setImages({ ...response.data });
      })
  }, [])

  const uploadPhoto = (e, id) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Зургийн өргөтгөлтэй файл оруулна уу./png, jpg, jpeg/");
      return;
    }
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setImages(result);
          switch (id) {
            case 'license_front':
              images.license_front = result;
              setImages({ ...images });
              break;
            case 'license_back':
              images.license_back = result;
              setImages({ ...images });
              break;
            case 'car_cert':
              images.car_cert = result;
              setImages({ ...images });
              break;
            default:
              break;
          }
        }
      }
      fileReader.readAsDataURL(file);
    }
  }

  function saveImages() {
    LicenseCertService.save(images)
      .then((response) => {
        if (response === "success")
          navigate('/account-info')
      })
  }

  function handleClick() {    
    if (!isEmptyObject(images))
      saveImages();
  }

  return (
    <>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" />
        <Typography variant="Title" size="md">
          Жолоочийн мэдээлэл
        </Typography>
      </header>
      <Card>
        <Typography variant="Title" size="sm" className="text-black-64">
          Жолооны үнэмлэх зураг
        </Typography>
        <div className="grid grid-cols-2 gap-2 mt-4 mb-6">
          <div className="space-y-2">
            <Typography variant="Label" size="md" className="font-semibold">
              Урд тал
            </Typography>
            <ImportImage
              id="license_front"
              image={images.license_front}
              onChange={uploadPhoto}
              className="w-full h-24"
            />
          </div>
          <div className="space-y-2">
            <Typography variant="Label" size="md" className="font-semibold">
              Хойд тал
            </Typography>
            <ImportImage
              id="license_back"
              image={images.license_back}
              onChange={uploadPhoto}
              className="w-full h-24"
            />
          </div>
        </div>
        <div className="my-4">
          <Typography variant="Title" size="sm">
            Тээврийн хэрэгслийн гэрчилгээ
          </Typography>
          <ImportImage
            id="car_cert"
            image={images.car_cert}
            onChange={uploadPhoto}
            className="w-full h-52 mt-4"
          />
        </div>
        <Button className="w-full" onClick={() => handleClick()}>
          Үргэлжлүүлэх
        </Button>
      </Card>
    </>
  )
}

export default DriverInfo;