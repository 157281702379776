import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ClipLoader } from "react-spinners";

import { useStore } from "../store";
import Notification from "../base-components/Notification";

const Layout = () => {
  const notifyRef = useRef(null);
  const loading = useStore.useLoading();

  const notification = useStore.useNotification();

  useEffect(() => {
    if (notification) notifyRef.current.show();
  }, [notification]);

  return (
    <div className="h-full flex justify-center">
      <div className="relative bg-gray h-max min-h-full w-full sm:w-[420px] md:w-[420px] lg:w-[420px] xl:[420px] px-4 pt-8">
        <div
          className={twMerge([
            "flex justify-center items-center z-50 absolute top-0 bottom-0 right-0 left-0 bg-primary opacity-30",
            !loading && "hidden",
          ])}
        >
          <ClipLoader color="#fff" size={40} />
        </div>
        <Notification
          ref={notifyRef}
          message={notification?.message}
          type={notification?.type}
        />
        <img
          src="/images/icon.png"
          alt="agula icon"
          className="absolute right-0 top-0"
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
