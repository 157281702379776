import { TextField } from "@mui/material";
import { forwardRef } from "react";

interface InputProps {
  label?: string;
  className?: string;
}
const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const className = props.className;
  const label = props.label;

  return (
    <TextField
      {...props}
      ref={ref}
      sx={{
        "& .MuiOutlinedInput-root": {
          fontFamily: "Inter",
          border: "1px solid #D9E0E8",
          borderRadius: 2,
          fontWeight: 600,
          fontSize: "0.875rem" /* 14px */,
          lineHeight: "1.25rem" /* 20px */,
          color: "#09111B",
          // letterSpacing: '0.025em',
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
          background: "#fff",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "#C0CBD8",
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Inter",
          color: "#465972",
          fontSize: "0.875rem" /* 14px */,
          lineHeight: "1.25rem" /* 20px */,
          transform: "translate(1rem, 1rem)",
          fontWeight: 500,
        },
        "& .MuiInputLabel-shrink": {
          color: "#465972",
          transform: "translate(1rem, .25rem)",
          backgroundColor: "transparent",
          fontSize: "0.75rem" /* 12px */,
          lineHeight: "1rem" /* 16px */,
          pointerEvents: "none",
        },
        "& .MuiOutlinedInput-input": {
          padding: "1rem",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          display: "none",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#465972",
        },
      }}
      className={className}
    />
  );
});

Input.displayName = "Input";

export default Input;
