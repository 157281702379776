import Service from "./BaseService";

const get = () =>
  Service.get('/images/case');

const save = (payload, config) =>
  Service.upload('/images/case', payload, config);

const CaseService = {
  get,
  save,
};

export default CaseService;