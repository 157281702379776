import { useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Camera as CameraPro } from "react-camera-pro";

import { ReactComponent as CameraIcon } from "../../assets/icons/camera-1.svg";
import IconButton from "../../base-components/IconButton";
import Button from "../../base-components/Button";
import Typography from "../../base-components/Typography";

import { useStore } from "../../store";

const Camera = () => {
  const navigate = useNavigate();

  const image = useStore.useImage();
  const setImage = useStore.useSetImage();
  const setImageChanged = useStore.useSetImageChanged();
  const cameraRef = useRef(null);

  const page = useStore.usePage();

  function capturePhoto() {
    const imageSrc = cameraRef.current.takePhoto();
    setImage(imageSrc);
    setImageChanged(true)
  }

  return (
    <div className='absolute inset-0'>
      {
        image ?
          <img src={image} alt='car' className="h-full w-full inset-0" />
          :
          <CameraPro
            ref={cameraRef}
            facingMode='environment'
            className="h-full w-full inset-0" />
      }
      <div className="mt-12 absolute inset-x-0 bottom-8">
        <div className="grid p-9 gap-6">
          <Typography size="md" type="white" className="text-center">
            Зураг тод дарснаар нөхөн төлбөр шийдвэрлэхэд илүү хялбар болно шүү
          </Typography>
          <div className="flex justify-center gap-6">
            {
              image ? (
                <Fragment>
                  <Button variant="primary-outlined" onClick={() => setImage('')}>
                    Дахин дарах
                  </Button>
                  <Button
                    variant="primary-soft"
                    onClick={() => navigate(`${page}/edit`)}
                  >
                    Үргэлжлүүлэх
                  </Button>
                </Fragment>
              )
                :
                <IconButton variant="secondary-outlined" className="bg-white" onClick={capturePhoto}>
                  <CameraIcon />
                </IconButton>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Camera;