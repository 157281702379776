import { useNavigate } from "react-router";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ShieldCheck } from "../../assets/icons/shield-check-1.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevron-down.svg";

// import { useStore } from "../../store";
import { stringValue, thumbColor } from "../../utils/helper";
import {
  DAMAGE_LEVEL_MN,
  DAMAGE_TYPE,
  DAMAGE_TYPE_ESTIMATION,
  REPAIR_TYPE,
} from "../../utils/constant";
import Typography from "../../base-components/Typography";
import IconButton from "../../base-components/IconButton";
import SliderLevel from "../../base-components/Slider";
import MuiAccordion, {
  MuiAccordionDetails,
  MuiAccordionHeader,
} from "../../base-components/Accordion";
import Button from "../../base-components/Button";
import Card from "../../components/Card";
import useStore2 from "../../store/hook";
import EstimationService from "../../services/estimation/EstimationService";
import { useStoreBase } from "../../store";

const Result = () => {
  const navigate = useNavigate();

  const damagedParts = useStore2.useDamagedParts();
  const vehicleInfo = useStore2.useVehicleInfo();
  const victim = useStore2.useVictim();
  // const tokenData = useStore.useTokenData();

  const renderDamages = () => {
    if (damagedParts.length > 0) {
      return damagedParts.map((part, idx) => (
        <MuiAccordion key={`damaged-part-${idx}`}>
          <MuiAccordionHeader
            expandIcon={
              <IconButton size="sm" variant="primary-soft">
                <Chevron className="h-[18px] w-[18px]" />
              </IconButton>
            }
          >
            <div className="flex items-center space-x-3">
              <div className="inline-flex justify-center items-center h-10 w-10 rounded-full bg-secondary-12">
                <ShieldCheck fill="#E3A13A" />
              </div>
              <div className="grow">
                <Typography variant="Title" size="sm">
                  Гэмтэл - {idx + 1}
                </Typography>
                <Typography size="md" className="text-black-64">
                  {stringValue(part.part_name_mn)}
                </Typography>
              </div>
            </div>
          </MuiAccordionHeader>
          <MuiAccordionDetails>
            <div className="rounded-lg mb-3 flex justify-center">
              <img
                src={
                  part.labeled_image ? part.labeled_image : "/images/damage.png"
                }
                alt="damage"
              />
            </div>
            <div className="border border-dark-12 rounded-lg bg-white py-2.5 px-4">
              <div className="flex items-center space-x-2">
                <Typography size="sm" className="text-black-64">
                  Эвдрэлийн зэрэг:
                </Typography>
                <Typography
                  variant="Title"
                  size="sm"
                  className={thumbColor(part.damage_level)}
                >
                  {DAMAGE_LEVEL_MN[part.damage_level]}
                </Typography>
              </div>
              <SliderLevel level={part.damage_level} />
              <div className="grid grid-cols-2 my-2">
                <div>
                  <Typography size="sm" className="text-black-64">
                    Эвдрэлийн төрөл:
                  </Typography>
                  <Typography variant="Title" size="sm">
                    {DAMAGE_TYPE[part.damage_type]}
                  </Typography>
                </div>
                <div>
                  <Typography size="sm" className="text-black-64">
                    Засах/Солих:
                  </Typography>
                  <Typography variant="Title" size="sm">
                    {REPAIR_TYPE[part.repair_type]}
                  </Typography>
                </div>
              </div>
            </div>
          </MuiAccordionDetails>
        </MuiAccordion>
      ));
    } else {
      return;
    }
  };

  return (
    <div>
      <header className="flex items-center space-x-4">
        <Arrow
          className="h-[18px] w-[18px] cursor-pointer"
          onClick={() => navigate("/damages")}
        />
        <Typography variant="Title" size="md">
          Үнэлгээ хийгдэх зураг
        </Typography>
      </header>
      <Card>
        <section className="rounded-lg bg-primary relative flex justify-between py-4 px-3">
          <div className="absolute right-0 inset-y-0 inline-flex">
            <img src="/images/icon.png" alt="icon" />
          </div>
          <div>
            <Typography size="sm" className="text-white/[64%]">
              Улсын дугаар:
            </Typography>
            <Typography variant="Title" size="sm" className="text-white">
              {/* {stringValue(tokenData?.plate_number)} */}
              {vehicleInfo?.plateNumber}
            </Typography>
          </div>
          <div>
            <Typography size="sm" className="text-white/[64%]">
              Машины үйлдвэр, Марк:
            </Typography>
            <Typography variant="Title" size="sm" className="text-white">
              {vehicleInfo?.make + " " + vehicleInfo?.model}
            </Typography>
          </div>
        </section>
        <section className="my-2 space-y-2 divide-y divide-dark-12">
          {renderDamages()}
        </section>
      </Card>
      <Button
        className="w-full"
        onClick={() => {
          navigate("/calculations");
        }}
      >
        Үнэлгээ хийлгэх
      </Button>
    </div>
  );
};

export default Result;
