import IconButton from "../../base-components/IconButton";
import { ReactComponent as Close } from "../../assets/icons/cross.svg";
import Typography from "../../base-components/Typography";
import { twMerge } from "tailwind-merge";

const Instructions = ({ open, onClose = () => { } }) => {
  return (
    <div className={twMerge([
      "absolute inset-0 bg-black/[88%] hidden scale-up",
      open && "block"
    ])}>
      <div className="w-full h-full p-4 relative">
        <div className="absolute right-4">
          <IconButton
            variant="primary-outlined"
            onClick={onClose}
            className="bg-transparent border-white"
          >
            <Close fill="#fff" />
          </IconButton>
        </div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex justify-center">
          <div className="space-y-3 p-4">
            <div className="flex justify-center">
              <img src="/images/vin-zaavar.png" alt="vin instructions" />
            </div>
            <Typography type="white" className="text-center">
              Арлын дугаар нь жолооч талын дунд багана, хөдөлгүүрийн арын рам, урд салхины шилний доод хэсэгт байрлана
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Instructions;