import IconButton from "../../base-components/IconButton";
import Typography from "../../base-components/Typography";
import { ReactComponent as Facebook } from "../../assets/icons/fb.svg";

const Successful = () => {
  return (
    <>
      <div className="absolute inset-x-8 top-[calc(100%/2-200px)]">
        <div className="flex justify-center">
          <img src="/logos/vertical-primary.png" alt="primary logo" />
        </div>
        <Typography className="text-center mt-20 px-4">
          Таны материалыг хүлээн авсан тул энэхүү линк идэвхгүй болсон байна. Агула даатгалыг сонгосонд баярлалаа.
        </Typography>
        <Typography variant="Label" className="text-center mt-16">
          Холбогдох утас:  72101010
        </Typography>
        <div className="flex justify-center mt-6">
          <IconButton variant="primary-outlined" className="bg-transparent">
            <Facebook />
          </IconButton>
        </div>
      </div>
      {/* <div className="absolute bottom-4 inset-x-0 text-center">
        <Typography variant="Label">
          Developed by MKH LLC
        </Typography>
      </div> */}
    </>
  )
}

export default Successful;