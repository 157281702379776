import { StateCreator } from "zustand";
import { formatDateToYYMMDDHHMMSS } from "../utils/helper";
// [
//   {
//     "model": "Prius 41",
//     "market_price": "20-45 сая",
//     "spare_parts_price": 1250000,
//     "repair_price": null,
//     "material_price": 20000,
//     "labor_fee": 60000,
//     "class_name": "Урд буфер",
//     "type_of_breakdown": "хагарсан"
//   },
//   {
//     "model": "Prius 41",
//     "market_price": "20-45 сая",
//     "spare_parts_price": 1050000,
//     "repair_price": null,
//     "material_price": 0,
//     "labor_fee": 40000,
//     "class_name": "Урд гэрэл",
//     "type_of_breakdown": "хагарсан"
//   }
// ]
interface iEst {
  model: string;
  market_price: string;
  spare_parts_price: number | null;
  repair_price: number | null;
  material_price: number | null;
  labor_fee: number | null;
  class_name: string;
  type_of_breakdown: string;
}

export interface iEstimation {
  name: string;
  estimations: iEst[];
  setName: (plateNumber: string) => void;
  setEstimations: (estimations: iEst[]) => void;
}

const createEstimationSlice: StateCreator<iEstimation> = (set) => ({
  name: "",
  estimations: [],
  setName: (plateNumber) =>
    set(() => {
      return {
        name: `${formatDateToYYMMDDHHMMSS(
          "Asia/Ulaanbaatar"
        )} - ${plateNumber}`,
      };
    }),
  setEstimations: (estimations) => set(() => ({ estimations: estimations })),
});
export default createEstimationSlice;
