import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Typography from "../Typography";

interface ButtonProps {
  className?: string;
  variant?: string;
  size?: string;
  icon?: any;
  textSize?: "lg" | "md" | "sm";
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    variant = "primary-contained",
    size = "lg",
    icon,
    textSize = "lg",
    disabled = false,
    onClick,
    children,
  } = props;

  const generalStyles = [
    "px-4 rounded-lg text-white flex justify-center items-center space-x-2 font-medium tracking-[.25px] outline-none",
  ];

  // Sizes
  const small = ["text-xs py-3 h-10"];
  const large = ["text-sm py-3.5 h-12"];

  // Main color
  const primary_contained = [
    "bg-primary", // Default
    "focus:bg-primary-focus focus:text-white-64", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-hover [&:hover:not(:disabled)]:text-white-64", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-black-24", // Disabled
  ];
  const secondary_contained = [
    "bg-secondary text-primary", // Default
    "focus:bg-secondary-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-black-24", // Disabled
  ];

  // Outline
  const primary_outlined = [
    "border border-dark-12 text-primary", // Default
    "focus:bg-primary-soft-while-pressing focus:text-black-64", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-black-64", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-black-24", // Disabled
  ];
  const secondary_outlined = [
    "border border-stroke-secondary-20 bg-white text-on-surface-secondary", // Default
    "focus:bg-secondary-soft-focus focus:border-stroke-secondary-12", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-while-pressing [&:hover:not(:disabled)]:border-stroke-secondary-12", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-black-24 disabled:border-stroke-secondary-12", // Disabled
  ];

  // Soft color
  const primary_soft = [
    "bg-primary-soft text-primary", // Default
    "focus:bg-primary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-hover", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-black-24", // Disabled
  ];

  const secondary_soft = [
    "bg-secondary-soft text-secondary-150", // Default
    "focus:bg-secondary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-black-24", // Disabled
  ];

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      {...props}
      ref={ref}
      className={twMerge([
        generalStyles,
        size === "sm" && small,
        size === "lg" && large,
        variant === "primary-contained" && primary_contained,
        variant === "secondary-contained" && secondary_contained,
        variant === "primary-outlined" && primary_outlined,
        variant === "secondary-outlined" && secondary_outlined,
        variant === "primary-soft" && primary_soft,
        variant === "secondary-soft" && secondary_soft,
        className,
      ])}
    >
      {icon}
      <Typography variant="Label" size={textSize}>
        {children}
      </Typography>
    </button>
  );
});
Button.displayName = "Button";

export default Button;
