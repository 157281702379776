import { Fragment } from "react";
import { useFormContext, Controller } from "react-hook-form";

import InfoService from "../../../services/InfoService";
import { ReactComponent as Car } from "../../../assets/icons/car-1.svg";

import Select from "../../../base-components/Select";
import Input from "../../../base-components/Input";
import DatePicker from "../../../base-components/DatePicker";
import Typography from "../../../base-components/Typography";
import Card from "../../../components/Card";

const VehicleInfo = () => {
  const { register, control, formState: { errors } } = useFormContext({
    vin: '',
    plate_number: '',
    make: -1,
    model: -1,
    color: -1,
    year: -1,
    imported_date: '',
  });

  return (
    <>
      <Typography variant="Title" size="md" className="w-60">
        Та тээврийн хэрэгслийн
        мэдээллийг бичиж оруулна уу
      </Typography>
      <Card className={"my-8"}>
        <header className="flex items-center space-x-3 mt-2">
          <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
            <Car fill="#E3A13A" className="w-4 h-4" />
          </div>
          <Typography variant="Title" size="md">
            Тээврийн хэрэгсэлийн мэдээлэл
          </Typography>
        </header>
        <div className="space-y-3 mt-4">
          <div>
            <Input
              label="Арлын дугаар"
              className="w-full"
              {...register('vin', {
                required: true,
              })}
            />
            {
              errors?.vin?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Арлын дугаараа оруулна уу.</p>
            }
          </div>
          <div>
            <Input
              label="Улсын дугаар"
              className="w-full"
              {...register('plate_number', {
                required: true,
                pattern: /^([0-9]{4})([А-ЯЁӨҮ]{3})$/,
              })}
            />
            {
              errors?.plate_number?.type === "required" &&
              <p className="text-xs text-error font-semibold mt-1 ml-1">Улсын дугаараа оруулна уу.</p>
            }
            {
              errors?.plate_number?.type === "pattern" &&
              <p className="text-error text-xs pl-2 mt-1">Улсын дугаар буруу байна.</p>
            }
          </div>
          <div>
            <Controller
              control={control}
              name='make'
              render={({ field: { value, onChange, onBlur } }) => (
                <Fragment>
                  <Select
                    label="Үйлдвэрлэгч"
                    id="makes"
                    service={InfoService.makeList}
                    value={value}
                    onChange={(option) => {
                      if(option)
                        onChange(option);
                    }}
                  />
                  {
                    errors?.make?.type === "required" &&
                    <p className="text-xs text-error font-semibold mt-1 ml-1">Үйлдвэрлэгчээ оруулна уу.</p>
                  }
                </Fragment>
              )}
              rules={{ required: true }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name='model'
              render={({ field: { value, onChange, onBlur } }) => (
                <Fragment>
                  <Select
                    label="Модел"
                    id="models"
                    service={InfoService.modelList}
                    value={value}
                    onChange={(option) => {
                      onChange(option);
                    }}
                  />
                  {
                    errors?.model?.type === "required" &&
                    <p className="text-xs text-error font-semibold mt-1 ml-1">Моделээ оруулна уу.</p>
                  }
                </Fragment>
              )}
              rules={{ required: true }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name='color'
              render={({ field: { value, onChange, onBlur } }) => (
                <Fragment>
                  <Select
                    label="Өнгө"
                    id="colors"
                    service={InfoService.colorList}
                    value={value}
                    onChange={(option) => {
                      onChange(option);
                    }}
                  />
                  {
                  errors?.color?.type === "required" &&
                  <p className="text-xs text-error font-semibold mt-1 ml-1">Өнгөө оруулна уу.</p>
                }
                </Fragment>
              )}
              rules={{ required: true }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name='year'
              render={({ field: { value, onChange, onBlur } }) => (
                <Fragment>
                  <Select
                    label="Үйлдвэрлэсэн он"
                    id="selection_year_id"
                    service={InfoService.yearList}
                    value={value}
                    onChange={(option) => {
                      onChange(option);
                    }}
                  />
                  {
                    errors?.year?.type === "required" &&
                    <p className="text-xs text-error font-semibold mt-1 ml-1">Үйлдвэрлэсэн оноо оруулна уу.</p>
                  }
                </Fragment>
              )}
              rules={{ required: true }}
            />
            
          </div>
          <div>
            <Controller
              control={control}
              name={'imported_date'}
              render={({ field: { value, onChange, onBlur } }) => (
                <Fragment>
                  <DatePicker 
                    label="Орж ирсэн огноо" 
                    id="imported_date"
                    date={value}
                    onChangeDate={(date) => {
                      onChange(date);
                    }}/>
                  {
                    errors?.imported_date?.type === "required" &&
                    <p className="text-xs text-error font-semibold mt-1 ml-1">Орж ирсэн огноогоо оруулна уу.</p>
                  }
                </Fragment>
              )}
              rules={{ required: true }}
            />
          </div>
        </div>
      </Card>
    </>
  )
}

export default VehicleInfo;