import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import RequestService from "../../services/RequestService";
import Card from "../../components/Card";
import Typography from "../../base-components/Typography";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";

const ApplicationForm = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({});

  useEffect(() => {
    RequestService.get().then((response) => setData(response.data)) 
  }, [])

  return (
    <Fragment>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" />
        <Typography variant="Title" size="md">
          Өргөдөл
        </Typography>
      </header>
      <Card size="lg" className={"space-y-4"}>
        <Typography variant="Title" size="md">
          {data.title}
        </Typography>
        <Typography>
          {data.text}
        </Typography>
        <div className="mt-10 flex justify-between">
          <div>
            <Typography variant="Label" className="mb-3">
              Нөхөн төлбөр авах данс:
            </Typography>
            <div className="flex space-x-2">
              <Typography size="md">
                Банк:
              </Typography>
              <Typography variant="Label">
                {data.bank_name}
              </Typography>
            </div>
            <div className="flex space-x-2">
              <Typography size="md">
                Данс:
              </Typography>
              <Typography variant="Label">
                {data.account_number}
              </Typography>
            </div>
            <div className="flex space-x-2">
              <Typography size="md">
                Нэр:
              </Typography>
              <Typography variant="Label">
                {data.account_name}
              </Typography>
            </div>
          </div>
          <button
            onClick={() => navigate('/signature')}
            className="outline-none border border-dashed border-secondary inline-flex justify-center items-center w-[108px] rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.12)]"
          >
            <Typography variant="Label" className="text-center">
              Гарын үсгээ зурна уу?
            </Typography>
          </button>
        </div>
      </Card>
    </Fragment>
  )
}

export default ApplicationForm