import { StateCreator } from "zustand";

export interface iIMage {
  image: string;
  page: string;
  imageChanged: boolean;
  setImage: (image: string) => void;
  setPage: (page: string) => void;
  setImageChanged: (changed: boolean) => void;
}

const createCameraSlice: StateCreator<iIMage> = (set) => ({
  image: "", // => base64 image
  page: "", // => /vin
  imageChanged: false,
  setImage: (image) => set(() => ({ image: image })),
  setPage: (page) => set(() => ({ page: page })),
  setImageChanged: (changed) => set(() => ({ imageChanged: changed })),
});

export default createCameraSlice;
