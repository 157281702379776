import { ReactComponent as Arrow } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as Pin } from "../../../assets/icons/pin-3-1.svg";
import { ReactComponent as Call } from "../../../assets/icons/call-redirected.svg";
import Typography from "../../../base-components/Typography";
import Card from "../../../components/Card";


const Contact = () => {
  return (
    <>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" onClick={() => window.location = 'warning'} />
        <Typography variant="Title" size="md">
          Холбоо барих
        </Typography>
      </header>
      <section className="grid place-content-center gap-2 mt-8">
        <div className="flex justify-center">
          <div className="w-14 h-14 bg-info-soft rounded-full flex justify-center items-center">
            <Pin fill="#1865D8" className="w-6 h-6" />
          </div>
        </div>
        <Typography variant="Label" className="text-center">
          Та хохирол үнэлгээний уламжлалт процесс руу шилжиж байна. Дараах газруудаар хохирлын үнэлгээг  хийлгэх боломжтой.
        </Typography>
      </section>
      <section className="mt-8">
        <Card size="sm" className={'flex items-center space-x-4 p-2 pr-4'}>
          <img src="/baiguullagiin-logos/ashid-bilguun.png" alt="ashid bilguun" />
          <div className="grow">
            <Typography variant="Title" size="md">
              Ашид Билгүүн ХХК
            </Typography>
            <Typography size="md" className="text-black-64">
              Утас: 88101112
            </Typography>
          </div>
          <Call className="w-5 h-5" />
        </Card>
        <Card size="sm" className={'flex items-center space-x-4 p-2 pr-4'}>
          <img src="/baiguullagiin-logos/vendo.png" alt="ashid bilguun" />
          <div className="grow">
            <Typography variant="Title" size="md">
              Вендо ХХК
            </Typography>
            <Typography size="md" className="text-black-64">
              Утас: 95901214
            </Typography>
          </div>
          <Call className="w-5 h-5" />
        </Card>
        <Card size="sm" className={'flex items-center space-x-4 p-2 pr-4'}>
          <img src="/baiguullagiin-logos/master-unelgee.png" alt="ashid bilguun" />
          <div className="grow">
            <Typography variant="Title" size="md">
              Мастер Үнэлгээ ХХК
            </Typography>
            <Typography size="md" className="text-black-64">
              Утас: 99113322
            </Typography>
          </div>
          <Call className="w-5 h-5" />
        </Card>
        <Card size="sm" className={'flex items-center space-x-4 p-2 pr-4'}>
          <img src="/baiguullagiin-logos/best-estiment.png" alt="ashid bilguun" />
          <div className="grow">
            <Typography variant="Title" size="md">
              БЭСТ ЭСТИМЕЙТ ХХК
            </Typography>
            <Typography size="md" className="text-black-64">
              Утас: 70112233
            </Typography>
          </div>
          <Call className="w-5 h-5" />
        </Card>
      </section>
    </>
  )
}

export default Contact;