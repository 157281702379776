import { twMerge } from "tailwind-merge";
import { ReactComponent as PlusCircle } from "../../assets/icons/plus-circle-1.svg";
import Typography from "../../base-components/Typography";

const TakePhoto = ({ className, onTakePhoto = () => {} }) => {
  return (
    <button
      onClick={onTakePhoto}
      className={twMerge([
        "outline-none border border-dashed border-primary rounded-lg bg-white h-52 grid place-content-center gap-2 w-full",
        className,
      ])}
    >
      <div className="flex justify-center">
        <PlusCircle fill="#E2A95C" className="flex justify-center" />
      </div>
      <Typography variant="Label">
        Зураг дарах
      </Typography>
    </button>
  )
}

export default TakePhoto;