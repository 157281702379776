import { useNavigate } from "react-router";
import AlertModal from "../../../base-components/AlertModal";
import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";

const Warning = () => {
  const navigate = useNavigate();

  return (
    <>
      <AlertModal
        type="warning"
        isOpen={true}
      >
        <div className="space-y-4 mb-10">
          <AlertModal.Title className={"text-secondary"}>
            Та үнийн дүнг хүлээн зөвшөөрөхгүй тохиолдолд уламжлалт хохирол үнэлгээний процесс руу шилжих болно.
          </AlertModal.Title>
          <div className="border-b border-dark-20 mx-10 !my-6"></div>
          <section className="space-y-2">
            <Typography variant="Title" size="md">Санамж - 1</Typography>
            <Typography size="md">
              Уламжлалт аргаар хохирол үнэлэхэд энэхүү тооцооллоос 15%-иар их эсвэл бага гарсан тохиолдолд манай компани хохирол үнэлгээний төлбөрийг төлөхгүй болохыг анхаарна уу.
            </Typography>
          </section>
          <section className="space-y-2">
            <Typography variant="Title" size="md">Санамж - 2</Typography>
            <Typography size="md">
              Уламжлалт аргаар үнэлсэн хохиролын хэмжээ энэхүү тооцооллоос бага гарсан тохиолдолд энэхүү тооцооллыг эргэж ашиглахгүй болохыг анхаарна уу.
            </Typography>
          </section>
        </div>
        <AlertModal.Footer className={"grid grid-cols-2 gap-4"}>
          <Button onClick={() => navigate('/price-not-allowed')} variant="primary-outlined">
            Буцах
          </Button>
          <Button onClick={() => window.location = 'contact'}>
            Үргэлжлүүлэх
          </Button>
        </AlertModal.Footer>
      </AlertModal>
    </>
  )
}

export default Warning;