import { useState, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import Typography from "../../base-components/Typography";
import { ReactComponent as PlusCircle } from "../../assets/icons/plus-circle-1.svg";
import { ReactComponent as Cross } from "../../assets/icons/cross-circle.svg";

export const imageMimeType = /image\/(png|jpg|jpeg)/i;

const ImportImage = (props) => {
  const {
    className,
    onChange = () => { },
    image,
    id,
  } = props;

  return (
    <>
      <label htmlFor={`${id}-image-file`}>
        <div
          id="img-wrapper"
          className={twMerge([
            "relative border border-dashed border-dark-20 rounded-lg grid place-content-center gap-2 h-20 w-36 bg-black/[0.04]",
            image && "inline-flex border-solid",
            className,
          ])}
        >
          {
            image ?
              <>
                <div className="absolute top-2.5 right-2.5 inline-flex">
                  <Cross stroke="#09111B" className="w-[18px] h-[18px]" />
                </div>
                <img src={image} alt="choosePhoto" className="rounded-lg" />
              </>
              :
              <>
                <div className="flex justify-center">
                  <PlusCircle className="flex justify-center" />
                </div>
                <Typography variant="Label" size="md" className="font-semibold">
                  Зураг нэмэх
                </Typography>
              </>
          }
        </div>
      </label>
      <input
        id={`${id}-image-file`}
        type="file"
        accept=".jpg, .jpeg, .png, .heic"
        className="hidden"
        onChange={(e) => onChange(e, id)}
      />
    </>
  )
}

export default ImportImage