import React from "react";

// components
import Typography from "../../base-components/Typography";
import { ReactComponent as Zuragu } from "../../assets/icons/zuragu-logo.svg";

export default function ThankYou() {
  return (
    <div className="absolute inset-0 flex">
      <div className="flex flex-col items-center justify-center w-full gap-20">
        <Zuragu />
        <Typography className="text-center">
          Биднийг сонгосонд баярлалаа. <br />
          Танд амжилт хүсье
        </Typography>
      </div>
    </div>
  );
}
