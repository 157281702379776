import { AxiosRequestConfig } from "axios";
import http from "./HttpClient";

const post = (
  url: string,
  payload: Object,
  config: AxiosRequestConfig<any> = {}
) =>
  http
    .post(url, payload, config)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));

const BaseService = {
  post,
};

export default BaseService;
