import { StateCreator } from "zustand";

interface iDamagedPart {
  part_name_mn: string;
  labeled_image: string;
  damage_level: "low" | "middle" | "high";
  damage_type: "scratch" | "dent" | "crack" | "severely";
  repair_type: "change" | "repair";
  deloitte_part: string;
}

export interface iDamagedParts {
  damagedParts: iDamagedPart[];
  setDamagedParts: (damagedParts: iDamagedPart[]) => void;
}

const createDamagedPartsSlice: StateCreator<iDamagedParts> = (set) => ({
  damagedParts: [],
  setDamagedParts: (damagedParts) =>
    set(() => ({ damagedParts: damagedParts })),
});
export default createDamagedPartsSlice;
