import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { createSelectorHooks } from "auto-zustand-selectors-hook";

import { createTokenSlice } from "./tokenSlice";
import { createCaseSlice } from "./caseSlice";
import { createInfoSlice } from "./infoSlice";
import { createLoadingSlice } from "./loaderSlice";
import { createNotificationSlice } from "./notificationSlice";

export const useStoreBase = create(
  devtools(
    persist(
      (...a) => ({
        ...createTokenSlice(...a),
        ...createCaseSlice(...a),
        ...createInfoSlice(...a),
        ...createLoadingSlice(...a),
        ...createNotificationSlice(...a),
      }),
      {
        name: "ai-store",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export const useStore = createSelectorHooks(useStoreBase);
