import Service from "./BaseService";

const get = () =>
  Service.get('/images/license-cert');

const save = (payload, config) =>
  Service.upload('/images/license-cert', payload, config);

const LicenseCertService = {
  get,
  save,
};

export default LicenseCertService;